import React from 'react'
import './Xatas.css'
import imgXatas from '../img/xatas.jpg'
import ComunModulo from './ComunModulo'

export default function Xatas ({usuario}) {

  return <ComunModulo
    usuario={usuario}
    zoomId='img-xatas-zoom'
    moduloId={4}
    imgModulo={imgXatas}
    titulo='MÓDULO 4 | 18 DE NOVEMBRO'
    subtitulo='A saúde dende o primeiro día.'
  />
}