import React from 'react'


export default function StandPopup ({handleClose, titulo, children}) {
  return <div
      className="absolute right-0 top-0 left-0 bottom-0 flex items-center"
      style={{backgroundColor: 'rgba(255, 255, 255, 0.4)'}}
    >
    <div className='rounded bg-white mx-auto mx-w-lg px-8 py-12 relative shadow-lg max-h-screen overflow-y-auto pb-8'>

      <button
        onClick={handleClose}
        className="absolute z-30 top-0 right-0 bg-blue-500 p-2 rounded-tr rounded-bl inline-flex
          items-center justify-center text-gray-100 hover:text-gray-200 hover:bg-blue-800
          focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
      >
        <span className="sr-only">Close</span>
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>

      <h2 className='font-bold text-xl'>{titulo}</h2>

      {children}
      <br />
      <br />
      <br />
    </div>
  </div>
}