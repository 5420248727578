import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/boumatic.jpg'
import imgVideo from '../../img/stands/boumatic-video.jpg'

export default function Boumatic () {

  const catalogos = [
    {nombre: 'Catálogo Boumatic Service Start', link: '/docs/boumatic/Ad_Service_Star_half_A4_portrait_EN_2021-05-20-081910.pdf'},
    {nombre: 'Catálogo AirStar DSL', link: '/docs/boumatic/AirStar-DSL.pdf'},
    {nombre: 'Catálogo Escorter 100', link: '/docs/boumatic/Escorter-100.pdf'},
    {nombre: 'Catálogo Flostar Xtreme', link: '/docs/boumatic/flostar-xtreme_210x148_v2.pdf'},
    {nombre: 'Catálogo FS Xtreme', link: '/docs/boumatic/FS_Xtreme.pdf'},
    {nombre: 'Catálogo GT2', link: '/docs/boumatic/GT2.pdf'},
    {nombre: 'Catálogo Handy Brush', link: '/docs/boumatic/handy_brush_ES.pdf'},
    {nombre: 'Catálogo HiFlo Evolution', link: '/docs/boumatic/HiFlo-Evolution.pdf'},
    // {nombre: 'Catálogo ', link: '/docs/boumatic/pic_2880x1420__VERTICAL.pdf'},
    {nombre: 'Catálogo BlueMax ', link: '/docs/boumatic/Promo_BlueMax_spain_2021frior_grupanor.pdf'},
    {nombre: 'Catálogo Realtime Activity', link: '/docs/boumatic/promotion_realtime_activity_avril_H151xL216_es_a4.pdf'},
    {nombre: 'Catálogo Slurry Systems', link: '/docs/boumatic/Publicidad-Slurry-systems.pdf'},
    {nombre: 'Catálogo Ranger', link: '/docs/boumatic/Ranger.pdf'},
    {nombre: 'Catálogo Smart Way', link: '/docs/boumatic/SmartWay-90.pdf'},
    {nombre: 'Catálogo SR2', link: '/docs/boumatic/SR2.pdf'},
    {nombre: 'Catálogo Streamline', link: '/docs/boumatic/Streamline.pdf'},
    {nombre: 'Catálogo Xpedia 360 EX', link: '/docs/boumatic/Xpedia-360-EX.pdf'},
    {nombre: 'Catálogo Xpedia 360 IX', link: '/docs/boumatic/Xpedia-360-IX.pdf'},
    {nombre: 'Catálogo Xpressway', link: '/docs/boumatic/Xpressway.pdf'},
  ]
  const videos = [
    {nombre: 'Boumatic Ranger ', link: 'https://player.vimeo.com/video/488012345'},
    {nombre: 'Boumatic Flo-Star Xtreme & Magnum Turbo ', link: 'https://player.vimeo.com/video/455815401'},
    {nombre: 'Flo-Star Xtreme ', link: 'https://player.vimeo.com/video/455740414'},
    {nombre: 'HiFlo Evolution Pulsator ', link: 'https://player.vimeo.com/video/221608891'},
    {nombre: 'Productos Boumatic | Xornadas Seragro 2021 ', link: 'https://youtu.be/1_YgTrIcpb8'},
    {nombre: 'Publirreportaje CAP Coruña', link: 'https://vacapinta.com/media/files/fichero/vp018_instalacion_sala_rotativa_boumatic_capcoruna.pdf'},
    {nombre: 'Vídeo CAP Coruña ', link: 'https://vacapinta.com/es/tv/empresas/la-cap-coruna-confia-el-ordeno-boumatic-en-su-nuev.html'},
    {nombre: 'Publirreportaje Gemini Portugal', link: 'https://vacapinta.com/media/files/fichero/vp024_boumatic_gemini.pdf'},
    {nombre: 'Vídeo Robot Gemini 1', link: 'https://vacapinta.com/es/tv/empresas/boumatic-instala-el-primer-robot-gemini-en-una-gra.html'},
    {nombre: 'Vídeo Robot Gemini 2', link: 'https://vacapinta.com/es/tv/empresas/boumatic-instala-el-primer-robot-gemini-en-una-gra/4500-agros-comercial-instala-el-primer-robot-gemini-en-.html'},
    {nombre: 'Vídeo Robot Gemini 3  ', link: 'https://vacapinta.com/es/tv/empresas/boumatic-instala-el-primer-robot-gemini-en-una-gra/4501-lactolaxia-cuenta-con-el-primer-robot-gemini-box-d.html'},
  ]
  const contactoInfo = <div>
    <br/>
    Boumatic<br/>
    Grupanor Cercampo S.A.<br/>
    Azufre, 4<br/>
    28850 Torrejón de Ardoz (Madrid)<br/>
    Tel: 609 277 617<br/>
    <br/>
    Frior S.L.<br/>
    Pol. Industrial Pedrapartida, parcela 17<br/>
    15316 Coirós (A Coruña)<br/>
    Tel: 981 774 500<br/>
    Javier Liste<br/>
    Delegado para España e Portugal<br/>
    Tel: 680 889 137<br/>
    jliste@boumatic.com<br />
    <br/>
  </div>
  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/boumatic.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='jliste@boumatic.com'
      handleClickPoster={() => {
        window.open('/docs/boumatic/promotion_realtime_activity_avril_H151xL216_es_a4.pdf', '_blank')
      }}
      handleClickRollup={() => {
        window.open('https://boumatic.com', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://boumatic.com', '_blank')
      }}

    />
  )
}