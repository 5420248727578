import React, { useRef, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import {
  Link,
  Route,
  withRouter
} from 'react-router-dom'

import './App.css';
import imgRotate from './img/rotate.png'
import imgBackground from './img/bg.jpg'
import logoSeragro from './img/seragro-logo.png'
import descargaRevista from './img/descarga-revista.min.png'

import Silos from './components/Silos'
import Camas from './components/Camas'
import Sala from './components/Sala'
import Xatas from './components/Xatas'
import Rexistro from './components/Rexistro'
import Stands from './components/stands/Stands'
import Millo from './components/Millo'
import Colaboradores from './components/Colaboradores'
import StandElanco from './components/stands/StandElanco'
import StandXesga from './components/stands/StandXesga'
import NotaLegal from './components/NotaLegal'
import Brinde from './components/Brinde'
// import Crono from './components/Crono'
import Adial from './components/stands/Adial'
import Boumatic from './components/stands/Boumatic'
import Calier from './components/stands/Calier'
import Ceva from './components/stands/Ceva'
import Corteva from './components/stands/Corteva'
import Karizoo from './components/stands/Karizoo'
import Progando from './components/stands/Progando'
import Syngenta from './components/stands/Syngenta'
import Wisium from './components/stands/Wisium'
import Zoetis from './components/stands/Zoetis'
import Boehringer from './components/stands/Boehringer'
import Fatro from './components/stands/Fatro'
import Msd from './components/stands/Msd'
import Hipra from './components/stands/Hipra'
import Galeno from './components/stands/Galeno'

const App = ({history}) => {
  const [usuario, setUsuario] = useState(false)
  const scrollInto = useRef(null)


  useEffect(() => {
    scrollInto.current.scrollIntoView()

    setUsuario(localStorage.getItem('emailForSignIn'))

  }, [usuario, history])

  return <>

    <div
      id="rotate-device-warning"
      className='z-40 absolute top-0 bottom-0 left-0 right-0 p-6
            justify-center items-center'
    >
      <div
        className='bg-red-500 rounded text-white p-4 text-xl text-bold'
      >
        Pon o teu móbil en horizontal para unha correcta visualización.
        <img src={imgRotate} alt="rotar mobil" className="mt-4 "/>
      </div>
    </div>

    <div className="container" ref={scrollInto}>
      <ReactTooltip html={true} />

      <div
        className='fixed top-0 left-0 md:left-auto md:right-32 bg-white z-10 w-20 sm:w-24 md:w-32 lg:w-44 p-4 rounded-br-lg md:rounded-b-lg'
      >
        <img
          src={logoSeragro}
          alt='Logo'
          className='mx-auto w-full'
        />
        {/*
        <address
          className='text-xs mt-2 text-center hidden lg:block'
        >
          Castiñeiras, 112-A2 Polígono Novo Milladoiro Ames
          A Coruña 15895 España
        </address>
        { */ }
      </div>
      {/*
      <div className='fixed bottom-0 right-0 md:right-28 md:right-32 bg-white z-10 w-32 md:w-32 lg:w-44  rounded-tl-lg md:rounded-t-lg'>
        <Crono />
      </div>
      */}
      <div className='fixed -bottom-4 bottom-0 -right-4 md:right-24 lg:right-24 z-10 w-60 md:w-72 lg:w-96 rounded-tl-lg md:rounded-t-lg'>
        <a href='/docs/revista-seragro-2021-v3.pdf' target='_blank' rel="noreferrer">
          <img src={descargaRevista} alt='Descargar a revista'/></a>
      </div>

      <div
        className='fixed bottom-0 left-0'
      >
        <div
          className='rounded-tr-lg text-center p-2 md:p-3 text-xs md:text-base bg-white '
        >
          <Link to='/nota-legal' className='mx-4 text-theme font-bold'>Nota Legal</Link>
          <b className='text-theme'>|</b>
          <Link to='/colaboradores' className='mx-4 text-theme font-bold'>Colaboradores</Link>
        </div>
      </div>

      <svg
        version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 508 285.75"
        id="mapa"
        className='md:my-0 mx-auto mt-11'
      >

        <image
          xlinkHref={imgBackground}
          /*style="stroke:none;fill:#b44646;fill-opacity:1"*/
          width="508"
          height="285.75"
          x="-3.6083984e-06"
          y="-4.536133e-06"
          id="image20"
          preserveAspectRatio="none"
        />

        <filter id="svg-blur" x="0" y="0">
          <feGaussianBlur in="SourceGraphic" stdDeviation="2"/>
        </filter>
        <path
          /*style="fill:#be4b4b;stroke:none;stroke-width:0;fill-opacity:0.47501296"*/
          style={{ fill: '#00ABEB', stroke: 'none', fillOpacity: 0.45 }}
          d="m 68.98909,186.48337 -15.85415,0.29647 -4.17384,8.56998 10.6383,17.00007 10.1939,6.51928 31.52221,-0.95734 1.40996,-25.5064 -4.32953,-12.53886 -19.26964,-14.57591 -9.51199,4.86313 z"
          id="entrada" className="blink-area"
          data-tip='ENTRADA/REXISTRO'
          filter="url(#svg-blur)"
          onClickCapture={() => {
            history.push('/rexistro')
            ReactTooltip.hide()
          }}
        />
        <path
          /*style="fill:#be4b4b;stroke:none;stroke-width:0;fill-opacity:0.47501296"*/
          style={{ fill: '#00ABEB', stroke: 'none', fillOpacity: 0.45 }}
          d="m 78.22146,121.26708 -1.53078,-8.85202 0.95064,-2.824 54.93533,-13.7312 58.73911,46.50287 0.79388,13.42438 -31.21475,9.86987 -17.35917,-16.09845 -29.35091,9.62249 z"
          id="silos" className="blink-area"
          data-tip='<b>MÓDULO 3: 19 de Outubro</b> <br> Economía e nutrición.' data-html={true}
          filter="url(#svg-blur)"
          onClickCapture={() => {
            history.push('/silos')
            ReactTooltip.hide()
          }}
        />
        <path
          /*style="fill:#be4b4b;stroke:none;stroke-width:0;fill-opacity:0.47501296"*/
          style={{ fill: '#00ABEB', stroke: 'none', fillOpacity: 0.45 }}
          d="m 272.45353,118.30352 39.63399,-16.46532 45.44803,22.09473 -0.58549,7.04848 -1.69249,-1.82689 -5.20896,1.70944 -2.3944,3.5758 -4.43375,2.35713 -4.20251,-1.10163 -18.66654,10.04938 z"
          id="sala" className="blink-area"
          data-html={true} data-tip='<b>MÓDULO 2: 18 de Outubro</b> <br> Uso responsable de antibióticos. Unha necesidade e un beneficio.'
          filter="url(#svg-blur)"
          onClickCapture={() => {
            history.push('/sala')
            ReactTooltip.hide()
          }}
        />
        <path
          /*style="fill:#be4b4b;stroke:none;stroke-width:0;fill-opacity:0.47501296"*/
          style={{ fill: '#00ABEB', stroke: 'none', fillOpacity: 0.45 }}
          d="m 342.23762,91.34226 3.5955,2.50626 -0.1678,1.98132 3.26898,-1.44852 3.20226,1.6225 0.36435,2.4462 3.50674,-1.44394 2.6285,3.02459 0.45289,1.61107 4.01831,-1.98939 2.961,2.98449 -0.39743,2.43186 4.54189,-2.02826 1.97498,2.56678 0.69755,2.25323 3.73253,-1.99843 2.85155,1.7141 0.49411,3.71514 3.52464,-2.20942 3.4762,1.81617 1.29413,2.7825 2.14363,-0.93109 2.49201,0.68315 2.14082,2.65906 0.43844,1.51053 3.43276,-1.73301 4.34239,3.7231 0.03,1.7974 4.76205,-1.97265 3.17547,3.10351 0.23072,2.54037 4.89307,-1.77918 3.20704,2.46572 0.15174,8.49533 -29.84839,15.8161 1.309,-9.50105 -79.07173,-38.7196 z"
          id="xatas" className="blink-area"
          data-html={true} data-tip='<b>MÓDULO 4: 18 de Novembro</b> <br> A saúde dende o primeiro día.'
          filter="url(#svg-blur)"
          onClickCapture={() => {
            history.push('/xatas')
            ReactTooltip.hide()
          }}
        />
        <path
          /*style="fill:#be4b4b;stroke:none;stroke-width:0;fill-opacity:0.47501296"*/
          style={{ fill: '#00ABEB', stroke: 'none', fillOpacity: 0.45 }}
          d="m 245.4935,67.3484 24.66566,-7.07399 4.47651,-0.7878 68.58323,29.20274 -37.47386,14.66701 -64.05141,-29.99716 -0.24288,-6.03374 z"
          id="camas" className="blink-area"
          data-html={true} data-tip='<b>MÓDULO 1: 18 de Setembro</b> <br> Instalacións e benestar. Influencia na produción.'
          filter="url(#svg-blur)"
          onClickCapture={() => {
            history.push('/camas')
            ReactTooltip.hide()
          }}
        />
        <path
          /*style="fill:#be4b4b;stroke:none;stroke-width:0;fill-opacity:0.47501296"*/
          style={{ fill: '#00ABEB', stroke: 'none', fillOpacity: 0.45 }}
          d="m 190.66002,50.04291 -0.62324,-9.64354 -3.91887,-1.70392 -0.17387,-3.13988 62.53688,-13.56836 29.11332,9.14747 87.49525,-31.31311 10.98621,0.2622 110.57763,39.53447 0.33749,11.10029 -99.2077,45.45546 -108.6851,-44.69757 -59.40567,15.63518 z"
          id="purin" className="blink-area"
          data-html={true} data-tip='<b>MÓDULO 5: 19 de Novembro</b> <br> Medio ambiente e sostibilidade da produción láctea.'
          filter="url(#svg-blur)"
          onClickCapture={() => {
            history.push('/millo')
            ReactTooltip.hide()
          }}
        />
        <path
          /*style="fill:#be4b4b;stroke:none;stroke-width:0;fill-opacity:0.47501296"*/
          style={{ fill: '#00ABEB', stroke: 'none', fillOpacity: 0.45 }}
          d="m 412.428,104.34826 2.91573,-17.98235 19.37856,-8.94218 2.02338,0.54966 4.80118,-5.1102 2.01676,0.57324 1.29476,5.36575 16.32027,7.05465 7.64474,-3.67182 5.70873,7.36879 18.27207,6.38115 7.58219,-4.86588 3.07792,7.96391 4.81963,1.51066 0.0292,41.53682 z"
          id="stands" className="blink-area"
          data-html={true} data-tip='<b>ZONA DE PATROCINADORES</b>'
          filter="url(#svg-blur)"
          onClickCapture={() => {
            history.push('/stands')
            ReactTooltip.hide()
          }}
        />
        <path
          style={{ fill: '#00ABEB', stroke: 'none', fillOpacity: 0.45 }}
          d="m 166.24471,242.38995 52.56515,-23.57915 9.98803,6.95564 6.09491,14.24562 -0.41553,12.52769 -63.94252,33.48319 -15.39756,0.35964 -10.5416,-13.16748 2.92134,-7.54928 17.32422,-6.37464 -1.09032,-16.21322 z"
          id="brinde" className="blink-area"
          data-html={true} data-tip='<b>BRINDE DO LEITE</b>'
          filter="url(#svg-blur)"
          onClickCapture={() => {
            history.push('/brinde')
            ReactTooltip.hide()
          }}
        />
        <path
          d="m 33.483634,212.95681 23.04227,-9.78359 -2.57187,-4.17193 17.53193,1.80008 -7.02014,14.88346 -2.31867,-3.98408 -22.22191,11.13943 z"
          style={{ fill: '#00ABEB', stroke: 'none', fillOpacity: 1}}
          id="flecha"
        />
      </svg>
      </div>
      {/*
      <img id="img-zoom" src="./bg2.jpg" className="absolute top-0 left-0 bottom-0 right-0" />
        <div id="modal-zoom" className="absolute top-8 left-8 bottom-8 right-8 bg-white rounded-lg overflow-hidden ">
          <img src="./stand.jpg" className="max-w-full w-full" />
        </div>
      */}
      <Route path="/silos/:id?">
        <Silos usuario={usuario} />
      </Route>
      <Route path="/camas/:id?">
        <Camas usuario={usuario} />
      </Route>
      <Route path="/sala/:id?">
        <Sala usuario={usuario} />
      </Route>
      <Route path="/xatas/:id?">
        <Xatas usuario={usuario} />
      </Route>
      <Route path="/millo/:id?">
        <Millo usuario={usuario} />
      </Route>
      <Route path="/brinde/:id?">
        <Brinde usuario={usuario} />
      </Route>
      <Route path="/rexistro">
        <Rexistro />
      </Route>
      <Route path="/stands">
        <Stands />
      </Route>
      <Route path="/colaboradores">
        <Colaboradores />
      </Route>
      <Route path="/nota-legal">
        <NotaLegal />
      </Route>
      <Route path='/stands/elanco'>
        <StandElanco />
      </Route>
      <Route path='/stands/xesga'>
        <StandXesga />
      </Route>
      <Route path='/stands/adial'>
        <Adial />
      </Route>
      <Route path='/stands/boumatic'>
        <Boumatic />
      </Route>
      <Route path='/stands/calier'>
        <Calier />
      </Route>
      <Route path='/stands/ceva'>
        <Ceva />
      </Route>
      <Route path='/stands/corteva'>
        <Corteva />
      </Route>
      <Route path='/stands/karizoo'>
        <Karizoo />
      </Route>
      <Route path='/stands/progando'>
        <Progando />
      </Route>
      <Route path='/stands/syngenta'>
        <Syngenta />
      </Route>
      <Route path='/stands/wisium'>
        <Wisium />
      </Route>
      <Route path='/stands/zoetis'>
        <Zoetis />
      </Route>
      <Route path='/stands/boehringer'>
        <Boehringer />
      </Route>
      <Route path='/stands/fatro'>
        <Fatro />
      </Route>
      <Route path='/stands/msd'>
        <Msd />
      </Route>
      <Route path='/stands/hipra'>
        <Hipra />
      </Route>
      <Route path='/stands/galeno'>
        <Galeno />
      </Route>
    </>
}

export default withRouter(App);
