
export const FIREBASE_MODULES_DATA = {
    1: [
        {
            "id": "5eODYDdxyaJ947ddEyQs",
            "video": "https://youtu.be/eLp8h5mBzBI",
            "autor": "Víctor Manrique",
            "finalizado": true,
            "inicio": {
                "seconds": 1631971800,
                "nanoseconds": 0
            },
            "modulo": 1,
            "oculto": false,
            "nome": "Presentación",
            "orden": 0
        },
        {
            "id": "KZlcQlgAu0kCBETZPT9t",
            "artigo": "/docs/artigos/revista-seragro-juan-luis-mosquera.pdf",
            "inicio": {
                "seconds": 1631971800,
                "nanoseconds": 0
            },
            "visible": false,
            "patrocina_logo": "wisium.png",
            "nome": "Instalacións de vacas secas",
            "video": "https://youtu.be/Fv9HfM19dZE",
            "subtitulo": "Veterinario do Servizo de Alimentación de Seragro SCG",
            "patrocina": "wisium",
            "modulo": 1,
            "finalizado": true,
            "patrocina_url": "wisum",
            "orden": 1,
            "autor": "Juan Luis Mosquera Gómez",
            "avatar": "1-01.jpg",
            "iniciada": false
        },
        {
            "id": "8FqzTBvk39VEPqOjb0IJ",
            "patrocina": "msd",
            "patrocina_url": "msd",
            "patrocina_logo": "MSD.png",
            "artigo": "/docs/artigos/revista-seragro-carlos-carbonell.pdf",
            "orden": 2,
            "avatar": "1-02.jpg",
            "autor": "Carlos Carbonell Baeza",
            "nome": "O ABC nas instalacións de xatas",
            "visible": false,
            "iniciada": false,
            "modulo": 1,
            "finalizado": true,
            "subtitulo": "Veterinario especialista en recría. Servizo técnico de MSD Animal Health",
            "inicio": {
                "seconds": 1631974200,
                "nanoseconds": 0
            },
            "video": "https://youtu.be/KaugJitTmiM"
        },
        {
            "id": "H0pOR2mDRlIw5KRzfDfM",
            "inicio": {
                "seconds": 1631976600,
                "nanoseconds": 0
            },
            "iniciada": false,
            "visible": false,
            "avatar": "1-03.jpg",
            "artigo": "/docs/artigos/revista-seragro-carlos-romero.pdf",
            "video": "https://youtu.be/65WB1OOkXTQ",
            "finalizado": true,
            "nome": "Retorno económico do benestar animal",
            "modulo": 1,
            "subtitulo": "Responsable do Programa de Eficiencia Produtiva MID€S de Calidad Pascual",
            "orden": 3,
            "patrocina": "Boehringer",
            "patrocina_logo": "boehringer.png",
            "patrocina_url": "url",
            "autor": "Carlos Romero Sala"
        },
        {
            "id": "sdJKIQqUehKNl0stGWEC",
            "orden": 4,
            "modulo": 1,
            "finalizado": true,
            "visible": false,
            "inicio": {
                "seconds": 1631980200,
                "nanoseconds": 0
            },
            "subtitulo": "Interveñen: Juan Luis Mosquera, Carlos Carbonell, Carlos Romero e María Prado",
            "oculto": false,
            "video": "https://youtu.be/FP7L3UdmXDQ",
            "nome": "Rolda de preguntas"
        }
    ],
    2: [
        {
            "id": "QfdqcBoL1uJPeMcYBW1I",
            "nome": "Presentación",
            "video": "https://youtu.be/De35mRS5Omk",
            "visible": false,
            "autor": "Víctor López",
            "inicio": {
                "seconds": 1634563800,
                "nanoseconds": 0
            },
            "orden": 0,
            "finalizado": true,
            "oculto": false,
            "modulo": 2
        },
        {
            "id": "52ebwTPnoomf3tNj2nMf",
            "patrocina_url": "elanco",
            "nome": "Plan Nacional fronte á Resistencia a Antibióticos. Onde estamos e cara a onde imos",
            "avatar": "2-01.jpg",
            "video": "https://youtu.be/mYDpz2Y7C30",
            "subtitulo": "Coordinadora nacional do PRAN",
            "orden": 1,
            "visible": false,
            "inicio": {
                "seconds": 1634563800,
                "nanoseconds": 0
            },
            "patrocina": "elanco",
            "patrocina_logo": "elanco.png",
            "autor": "Cristina Muñoz Madero",
            "finalizado": true,
            "modulo": 2,
            "iniciada": false
        },
        {
            "id": "ogM5OiKF0wwgA1BlDImz",
            "nome": "Un posparto sen antibióticos",
            "inicio": {
                "seconds": 1634566200,
                "nanoseconds": 0
            },
            "finalizado": true,
            "avatar": "2-02.jpg",
            "artigo": "/docs/artigos/revista-seragro-angel-abuelo.pdf",
            "subtitulo": "Veterinario. Profesor de Sanidade e Benestar do Gando (Universidade Estatal de Michigan, EE.UU.)",
            "visible": false,
            "patrocina_logo": "ceva.png",
            "video": "https://youtu.be/AJ07RRGJL3Q",
            "iniciada": false,
            "patrocina": "ceva",
            "orden": 2,
            "modulo": 2,
            "patrocina_url": "ceva",
            "autor": "Ángel Abuelo Sebio"
        },
        {
            "id": "QAWGri0pQR8SU58gLf9J",
            "subtitulo": "Veterinaria do Servizo de Calidade do Leite de Seragro SCG",
            "autor": "Margarita Penelas López",
            "inicio": {
                "seconds": 1634568600,
                "nanoseconds": 0
            },
            "oculto": false,
            "video": "https://youtu.be/fEgNGT7YlsM",
            "iniciada": false,
            "finalizado": true,
            "nome": "Tratamento da mamite: moito máis que antibióticos",
            "modulo": 2,
            "artigo": "/docs/artigos/revista-seragro-marga-penelas.pdf",
            "visible": false,
            "avatar": "2-03.jpg",
            "patrocina_url": "hipra",
            "orden": 3,
            "patrocina_logo": "hipra.png",
            "patrocina": "hipra"
        },
        {
            "id": "QUWWaXOZkKi9p3mXEqwI",
            "nome": "Rolda de preguntas",
            "finalizado": true,
            "modulo": 2,
            "oculto": false,
            "visible": false,
            "inicio": {
                "seconds": 1634571000,
                "nanoseconds": 0
            },
            "orden": 4,
            "video": "https://youtu.be/WwHHAYvNt-Y"
        }
    ],
    3: [
        {
            "id": "v2fhCvWQAa86uQOabkQe",
            "subtitulo": "",
            "video": "https://youtu.be/26RTtI5FJso",
            "oculto": false,
            "visible": false,
            "modulo": 3,
            "orden": 0,
            "nome": "Presentación",
            "finalizado": true,
            "autor": "Albino Iglesias",
            "inicio": {
                "seconds": 1634650200,
                "nanoseconds": 0
            }
        },
        {
            "id": "SAO2F6vXUGtvB6hMvibz",
            "avatar": "3-01.jpg",
            "inicio": {
                "seconds": 1634650200,
                "nanoseconds": 0
            },
            "artigo": "/docs/artigos/revista-seragro-alejandro-aneiros.pdf",
            "iniciada": false,
            "patrocina": "corteva",
            "modulo": 3,
            "autor": "Alejandro Aneiros Martínez",
            "orden": 1,
            "finalizado": true,
            "patrocina_url": "corteva",
            "video": "https://youtu.be/rhIZDbdfkJY",
            "visible": false,
            "patrocina_logo": "corteva.png",
            "subtitulo": "Enxeñeiro Agronómo. Servizo de Xestión Técnico- Económica de Seragro SCG",
            "nome": "Canto custa producir un litro de leite en Galicia? Influencia da nutrición"
        },
        {
            "id": "DrzOiYiFakgzRkG3dgoJ",
            "modulo": 3,
            "avatar": "3-02.jpg",
            "autor": "Albart Coster",
            "vivible": "false",
            "inicio": {
                "seconds": 1634652600,
                "nanoseconds": 0
            },
            "subtitulo": "Enxeñeiro Agrónomo (PhD). Especialista en nutrición animal. Asesor independente (Holanda)",
            "visible": false,
            "video": "https://youtu.be/WjPEk7b7t2Q",
            "artigo": "/docs/artigos/revista-seragro-albart-coster.pdf",
            "patrocina": "progando",
            "finalizado": true,
            "orden": 2,
            "patrocina_logo": "progando.png",
            "patrocina_url": "progando",
            "iniciada": false,
            "nome": "Mellora da calidade do leite mediante a nutrición"
        },
        {
            "id": "uqedUpQbZSJnhapmsTJC",
            "orden": 3,
            "avatar": "3-03.jpg",
            "autor": "Alexandre Udina",
            "patrocina": "adial",
            "video": "https://youtu.be/nDpqhzKnAQ0",
            "iniciada": false,
            "patrocina_url": "adial",
            "patrocina_logo": "adial.png",
            "nome": "Ensilados de herba. Un risco e unha oportunidade",
            "artigo": "/docs/artigos/revista-seragro-alex-udina.pdf",
            "inicio": {
                "seconds": 1634655000,
                "nanoseconds": 0
            },
            "subtitulo": "Veterinario especialista en ensilado de forraxes",
            "visible": false,
            "modulo": 3,
            "finalizado": true
        },
        {
            "id": "XX5huJZpHfbIf32zKoCR",
            "modulo": 3,
            "video": "https://youtu.be/5bpfeKGH8v8",
            "orden": 4,
            "finalizado": true,
            "oculto": false,
            "nome": "Rolda de preguntas",
            "visible": false,
            "inicio": {
                "seconds": 1634657400,
                "nanoseconds": 0
            }
        }
    ],
    4: [
        {
            "id": "QwJgY5DEz5sG4Msu05Uu",
            "video": "https://youtu.be/bp3VvhNmFoE",
            "oculto": false,
            "inicio": {
                "seconds": 1637245800
            },
            "finalizado": true,
            "visible": false,
            "modulo": 4,
            "nome": "Presentación",
            "orden": 0
        },
        {
            "id": "byQD1gH1txJz4kfzvBOR",
            "finalizado": true,
            "patrocina": "zoetis",
            "subtitulo": "Veterinario. Profesor de Sanidade e Benestar do Gando (Universidade Estatal de Michigan, EE. UU.)",
            "video": "https://youtu.be/rsBIS7tAi3g",
            "avatar": "4-01.jpg",
            "artigo": "/docs/artigos/revista-seragro-angel-abuelo-terneras.pdf",
            "patrocina_url": "zoetis",
            "iniciada": false,
            "inicio": {
                "seconds": 1637245800,
                "nanoseconds": 0
            },
            "visible": false,
            "patrocina_logo": "zoetis.png",
            "nome": "O primeiro día da xata, o máis importante",
            "orden": 1,
            "autor": "Ángel Abuelo Sebio",
            "modulo": 4
        },
        {
            "id": "S6ZQIJdDmBF8z5yPaJUL",
            "inicio": {
                "seconds": 1637248200,
                "nanoseconds": 0
            },
            "modulo": 4,
            "orden": 2,
            "patrocina": "karizoo",
            "iniciada": false,
            "visible": false,
            "finalizado": true,
            "patrocina_logo": "karizoo.png",
            "artigo": "/docs/artigos/revista-seragro-juan-jose-sanchez.pdf",
            "autor": "Juan José Sánchez López",
            "nome": "A saúde podal conséguese dende o primeiro día",
            "avatar": "4-02.jpg",
            "patrocina_url": "karizoo",
            "video": "https://youtu.be/waw_oiC0OV0",
            "subtitulo": "Servizo de Podoloxía de Seragro SCG"
        },
        {
            "id": "4K2JZBhNcvWpvDgVt8Kn",
            "modulo": 4,
            "finalizado": true,
            "oculto": false,
            "orden": 3,
            "nome": "Rolda de preguntas",
            "video": "https://youtu.be/7twW_rZSW3A",
            "visible": false,
            "inicio": {
                "seconds": 1637250600,
                "nanoseconds": 0
            }
        }
    ],
    5: [
        {
            "id": "gHAT7KLTU4Da0x8S0Sp5",
            "visible": false,
            "nome": "Presentación",
            "modulo": 5,
            "oculto": false,
            "inicio": {
                "seconds": 1637332200,
                "nanoseconds": 0
            },
            "orden": 0,
            "video": "https://youtu.be/YZ3hmtVcNKw",
            "finalizado": true
        },
        {
            "id": "GbKtg0VJTDkUaFwckuEb",
            "autor": "Fernando Estellés Barber",
            "nome": "Emisións en ganderías de produción de leite. Magnitude real do problema",
            "patrocina_logo": "ovejero.png",
            "video": "https://youtu.be/XRsfjEKlp44",
            "patrocina": "ovejero",
            "subtitulo": "Profesor de Ciencia Animal e Tecnoloxía (UPV)",
            "visible": false,
            "inicio": {
                "seconds": 1637332200,
                "nanoseconds": 0
            },
            "modulo": 5,
            "avatar": "5-01.jpg",
            "patrocina_url": "ovejero",
            "artigo": "/docs/artigos/revista-seragro-fernando-estelles.pdf",
            "iniciada": false,
            "orden": 1,
            "finalizado": true
        },
        {
            "id": "5GR2AumwOCYHkX8wg7s7",
            "patrocina_logo": "syngenta_camponovo.png",
            "nome": "Redución de emisións. Que podemos facer na granxa?",
            "modulo": 5,
            "visible": false,
            "artigo": "/docs/artigos/revista-seragro-fernando-vicente-v2.pdf",
            "autor": "Fernando Vicente Mainar",
            "finalizado": true,
            "iniciada": false,
            "video": "https://youtu.be/9nx3Yt5azok",
            "patrocina_url": "syngenta",
            "avatar": "5-03.jpg",
            "inicio": {
                "seconds": 1637337000
            },
            "patrocina": "syngenta",
            "orden": 2,
            "subtitulo": "Investigador do Serida"
        },
        {
            "id": "fv60tzKn8VHWacqj1Ry3",
            "autor": "David Yáñez",
            "avatar": "5-02.jpg",
            "visible": false,
            "artigo": "/docs/artigos/revista-seragro-david-yanez.pdf",
            "patrocina_logo": "calier.png",
            "patrocina_url": "calier.png",
            "nome": "Reducción de emisiones. ¿Qué podemos hacer en granja?",
            "finalizado": true,
            "video": "https://youtu.be/G0khzM5I7o4",
            "orden": 3,
            "patrocina": "calier",
            "iniciada": false,
            "subtitulo": "Investigador científico del CSIC. Estación experimental de Zaidín (Granada)",
            "modulo": 5,
            "inicio": {
                "seconds": 1637334600
            }
        },
        {
            "id": "Cgt9hwLQ3H0miToiGzXF",
            "orden": 4,
            "finalizado": true,
            "visible": false,
            "nome": "Rolda de preguntas",
            "video": "https://youtu.be/-VCtoFF9C60",
            "inicio": {
                "seconds": 1637339400,
                "nanoseconds": 0
            },
            "modulo": 5,
            "oculto": false
        },
        {
            "id": "u9dSu5k46Xk2LidU61zO",
            "modulo": 5,
            "orden": 5,
            "finalizado": true,
            "patrocina_logo": "fatro_sergave.png",
            "visible": false,
            "oculto": false,
            "nome": "Mitos sobre saúde e sostibilidade asociados ao consumo de leite",
            "subtitulo": "Catedrático de Biotecnoloxía da UPV e divulgador científico",
            "video": "https://youtu.be/MOTN1MYFI14",
            "patrocina": "fatro",
            "avatar": "5-04.jpg",
            "autor": "J.M. Mulet",
            "inicio": {
                "seconds": 1637341200,
                "nanoseconds": 0
            },
            "artigo": "/docs/artigos/revista-seragro-mulet.pdf"
        },
        {
            "id": "Oz5LWUbqgJspGCLKppnv",
            "avatar": "../../logo.png",
            "video": "https://youtu.be/mabuf4Zca-k",
            "finalizado": true,
            "nome": "Brinde do leite",
            "inicio": {
                "seconds": 1637343000,
                "nanoseconds": 0
            },
            "visible": false,
            "modulo": 5,
            "orden": 6
        }
    ],
    6: [
        {
            "id": "2bxTQZ8RolmVw823Pg0k",
            "avatar": "../../logo.png",
            "video": "https://youtu.be/mabuf4Zca-k",
            "nome": "Brinde do leite",
            "autor": "",
            "modulo": 6,
            "inicio": {
                "seconds": 1637343000
            },
            "patrocina": "",
            "iniciada": false,
            "visible": false,
            "orden": "1",
            "patrocina_logo": "",
            "finalizado": true,
            "oculto": false,
            "subtitulo": "",
            "patrocina_url": "fatro"
        }
    ]
}