import React, { useState } from 'react'

export default function EmailForm({ emailTo }) {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    consulta: ''
  })

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      setLoading(true)
      const data = new URLSearchParams()
      data.append('nome', formData.nome)
      data.append('email', formData.email)
      data.append('consulta', formData.consulta)
      data.append('to', emailTo)

      const response = await fetch(
        '/video/send.php', {
          method: 'POST',
          body: data
        })
      setLoading(false)
      if (response.ok) {
        // setDone(true)
        alert('Consulta enviad con éxito')
        setFormData({
          nome: '',
          email: '',
          consulta: ''
        })
      } else {
        alert('Produciuse un erro enviado o email.')
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
      alert('Produciuse un erro. Ténteo de novo mais tarde.')
    }
  }

  return <form
    onSubmit={handleSubmit}
    className='rounded bg-white mx-auto mx-w-lg p-8'
  >
    <h4>Envía a tua consulta</h4>
    <div className="mb-4 mt-8">
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="name" type="text" required
        placeholder="Nome e apelidos"
        value={formData.nome}
        onChange={(e) => setFormData({...formData, nome: e.target.value})}
      />
    </div>

    <div className="mb-4">
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="email" type="email" required
        placeholder="nome@dominio.com"
        value={formData.email}
        onChange={(e) => setFormData({...formData, email: e.target.value})}
      />
    </div>
    <div className="mb-4">
      <textarea
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="Consulta" required
        value={formData.consulta}
        onChange={(e) => setFormData({...formData, consulta: e.target.value})}
      />
    </div>

    <div className="flex items-center justify-between">
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline inline-flex items-center"
        type="submit"
      >
        { loading &&
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
          fill="none" viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
          <path
            className="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>}
        Enviar
      </button>
    </div>
  </form>
}