import React from 'react'
import './Colaboradores.css'
import imgBackground from '../img/bg.jpg'
import patrocinadores from '../data/patrocinadores'
import colaboradores from '../data/colaboradores'
import BtnClose from './BtnClose'

export default function Colaboradores () {

  return <>
    <img id="img-stands-zoom" src={imgBackground} alt='background' className="absolute top-0 left-0 bottom-0 right-0 z-20" />
    <div className="modal-zoom absolute top-8 left-8 h-screen right-8 bg-white rounded-lg z-20">

      <BtnClose to='/' />

      <div className='absolute top-10 left-0 h-screen right-0 p-8 overflow-y-auto'>

        <h2 className='text-theme font-bold text-4xl text-center mb-4'>Patrocinan:</h2>
        <div className='grid grid-cols-5 gap-4'>
          {patrocinadores.map((p) => {
            let href = p.link
            let target = '_blank'
            let rel = "noreferrer"
            if (href && href.startsWith('/')) {
              target = ''
              rel = ''
            }
            return <a key={p.name} href={href} target={target} rel={rel} className='mb-2'>
              <img src={p.img} alt={p.name}/>
            </a>
          })}
        </div>

        <h2 className='text-theme font-bold text-4xl text-center mb-4 mt-8'>Colaboran:</h2>
        <div className='grid grid-cols-7 gap-4'>
          {colaboradores.map((p) => {
            let target = '_blank'
            let rel = "noreferrer"
            if (p.link && !p.link.startsWith('http')) {
              target = ''
              rel = ''
            }
            return (
              <a
                key = {p.name}
                href = {p.link}
                target={target} rel={rel}
                className = 'mb-2'
              > { /* eslint-disable-line jsx-a11y/anchor-is-valid  */}
                <img src={p.img} alt={p.name} />
              </a>
            )
            })}
            <div className='pb-8'></div>
        </div>
        </div>
    </div>
  </>
}
