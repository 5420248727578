import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/msd.jpg'
import imgVideo from '../../img/stands/msd-video.jpg'

export default function Msd () {

  const catalogos = [
    {nombre: 'Catálogo Allflex', link: '/docs/msd/catalogo-allflex.pdf'},
    {nombre: 'Catálogo Fungibles', link: '/docs/msd/fungibles.pdf'},
    {nombre: 'Coccidiosis en el vacuno de cebo', link: '/docs/msd/coccidiosis.pdf'},
    {nombre: 'Buenas prácticas vacunales', link: '/docs/msd/buenas-practicas-vacunales.pdf'},
  ]
  const videos = [
    {nombre: 'Las 4 C\'s del calostro | Carlos Carbonell | MSD | Xornadas Seragro 2021 ', link: 'https://youtu.be/-nsVHxxyb10'},
    // {nombre: 'SAT Busto Corzón | MSD | Xornadas Seragro 2021', link: 'https://youtu.be/mA_PzhZHZt8'},
    {nombre: 'Testimonio Ignacio Puértolas | Allflex MSD | Xornadas Seragro 2021 ', link: 'https://youtu.be/cLbB0WKFfeM'},
  ]
  const contactoInfo = <div>
    <br/>
    msdahes@merck.com
  </div>

  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/msd.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='msdahes@merck.com'
      handleClickPoster={() => {
        window.open('https://www.vacunalavaca.com', '_blank')
      }}
      handleClickRollup={() => {
        window.open('/docs/ms/ROLL_UP_V2.pdf', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.vacunalavaca.com', '_blank')
      }}

    />
  )
}