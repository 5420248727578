import React from 'react'
import './Millo.css'
import ComunModulo from './ComunModulo'
import imgMillo from '../img/millo.jpg'

export default function Millo ({usuario}) {
  return <ComunModulo
    usuario={usuario}
    zoomId='img-millo-zoom'
    moduloId={5}
    imgModulo={imgMillo}
    titulo='MÓDULO 5 | 19 DE NOVEMBRO'
    subtitulo='Medio ambiente e sostibilidade da produción láctea.'
  />
}