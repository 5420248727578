import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/corteva.jpg'
import imgVideo from '../../img/stands/corteva-video.jpg'

export default function Corteva () {

  const catalogos = [
    {nombre: 'Catálogo Inoculantes', link: '/docs/corteva/catalogo-inoculantes.pdf'},
    {nombre: 'Catálogo Mix Praderas', link: '/docs/corteva/mix-praderas.pdf'},
    {nombre: 'Catálogo Raigrás Faraone', link: '/docs/corteva/pioneer-raigras-faraone.pdf'},
    {nombre: 'Catálogo Raigrás Lilio', link: '/docs/corteva/pioneer-raigras-lilio.pdf'},
  ]
  const videos = [
    {nombre: 'Inoculantes para ensilados de millo | Corteva | Xornadas Seragro 2021 ', link: 'https://youtu.be/AzOxjQxd38o'},
    {nombre: 'De la mesa al campo" | Corteva | Xornadas Seragro 2021 ', link: 'https://youtu.be/qPcZ_9CFAvI'},
  ]
  const contactoInfo = <div>
    <br/>
    Miguel Ángel Pose<br/>
    Responsable de Delegación<br/>
    620 863 710<br/>
    miguel.pose@corteva.com<br/>
    <br/>
    Francisco Fernández<br/>
    A Coruña<br/>
    653 688 615<br/>
    francisco.fernandez@europe.pioneer.com<br/>
    <br/>
    José L. Folgueira<br/>
    A Coruña nororiental y Centro de Lugo<br/>
    606 631 766<br/>
    Joseluis.folgueira@europe.pioneer.com<br/>
    <br/>
    Luis Iglesia<br/>
    Lugo norte y Asturias<br/>
    606 093 130<br/>
    luis.iglesia@europe.pioneer.com<br/>
    <br/>
    Ana Armada<br/>
    Lugo sur<br/>
    699 041 693<br/>
    ana.armada@europe.pioneer.com<br/>
    <br/>
    Adrián Varela<br/>
    Pontevedra y A Coruña sur<br/>
    628 684 113<br/>
  </div>


  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/corteva.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      emailContacto='miguel.pose@corteva.com'
      contactoInfo={contactoInfo}
      handleClickPoster={() => {
        window.open('https://www.cortevatalks.es', '_blank')
      }}
      handleClickRollup={() => {
        window.open('https://www.corteva.es/', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.corteva.es ', '_blank')
      }}

    />
  )
}