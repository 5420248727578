import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/fatro.jpg'
import imgVideo from '../../img/stands/fatro-video.jpg'

export default function Fatro () {

  const catalogos = [
    // {nombre: 'Catálogo ', link: '/'},
  ]
  const videos = []
  const contactoInfo = <div>
    <br/>
    María José Rodriguez<br/>
    Delegada de Fatro Ibérica en Galicia<br/>
    Teléfono: 607911629<br/>
    Correo electrónico: mrodriguez@fatroiberica.es<br/>
  </div>

  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/fatro.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='mrodriguez@fatroiberica.es'
      handleClickPoster={() => {
        window.open('https://www.fatroiberica.es/', '_blank')
      }}
      handleClickRollup={() => {
        window.open('https://www.sergave.es/', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.sergave.es/', '_blank')
      }}
      handleClickOtraWeb={() => {
        window.open('https://www.fatroiberica.es/', '_blank')
      }}
    />
  )
}