import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/galeno.jpg'
import imgVideo from '../../img/stands/galeno-video.jpg'

export default function Galeno () {

  const catalogos = [
    {nombre: 'Buenas prácticas vacunales', link: '/docs/galeno/buenas-practicas-vacunales.pdf'},
    {nombre: 'Catálogo Allflex', link: '/docs/galeno/allflex.pdf'},
    {nombre: 'Catálogo Coccidiosis', link: '/docs/galeno/coccidiosis.pdf'},
    {nombre: 'Catálogo de fungibles', link: '/docs/galeno/fungibles.pdf'},
  ]
  const videos = [
    {nombre: '', link: ''}
  ]
  const contactoInfo = <div>
    <br/>
    GALENO | ESPECIALIDADES VETERINARIAS S.L.<br/>
    Rúa Leiteiras nº 1, Polígono do Ceao<br/>
    27003 Lugo<br/>
    Número de teléfono: 982 209 511<br/>
    E-mail contacto : galeno@galeno.es<br/>
  </div>
  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/galeno.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='galeno@galeno.es'
      handleClickPoster={() => {
        window.open('https://www.galeno.es', '_blank')
      }}
      handleClickRollup={() => {
        window.open('https://www.galeno.es', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.galeno.es', '_blank')
      }}

    />
  )
}