import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/calier.jpg'
import imgVideo from '../../img/stands/calier-video.jpg'

export default function Calier () {

  const catalogos = [
    {nombre: 'Guía Bovino Julio 2021 ', link: '/docs/calier/guia-bovino-julio-2021.pdf'},
  ]
  const videos = [
    {nombre: 'Entrevista a Javier Sanz', link: 'https://www.youtube.com/watch?v=d0JAo092CsI'}
  ]
  const contactoInfo = <div>
    <br/>
    Cesar Manuel Vazquez<br/>
    cmvazquez@calier.es<br/>
    tel. 626 354 259<br/>
  </div>
  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/calier.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='info@calier.es'
      handleClickPoster={() => {
        window.open('https://www.calier.com/espana/es-es/vademecum/eprinovet', '_blank')
      }}
      handleClickRollup={() => {
        window.open('https://www.calier.com/espana/es-es/vademecum/eprinovet', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.calier.com/es', '_blank')
      }}

    />
  )
}
