import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/progando.jpg'
import imgVideo from '../../img/stands/progando-video.jpg'

export default function Progando () {

  const catalogos = [
    {nombre: 'Catálogo millo silo DEKALB', link: '/docs/progando/maiz-silo-deklab.pdf'},
    {nombre: 'Catálogo millo silo e forraxes Limagrain', link: '/docs/progando/maiz-silo-y-forrajeras.pdf'},
    {nombre: 'Catálogo ENTEC cultivos de forraxes', link: '/docs/progando/ficha-entec-cultivos-forrajeros.pdf'}
  ]
  const videos = [
    {nombre: 'Un fertilizante NPK de alto rendimiento para maíz | Entec | Xornadas Seragro 2021', link: 'https://youtu.be/EHA4CxKR08E'}
  ]
  const contactoInfo = <div>
    <br />
    Polígono Industrial de Sabón, Parcela 7A 15142 ARTEIXO - A Coruña<br />
    Tel.: 981 633 000<br />
    progando@progando.es<br />
  </div>
  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/progando.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='progando@progando.es'
      handleClickPoster={() => {
        window.open('https://www.progando.es/', '_blank')
      }}
      handleClickRollup={() => {
        window.open('https://www.progando.es/', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.progando.es/', '_blank')
      }}

    />
  )
}