import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/karizoo.jpg'
import imgVideo from '../../img/stands/karizoo-video.jpg'

export default function Karizoo () {

  const catalogos = [
    {nombre: 'Folleto Noreprinec ', link: '/docs/karizoo/noreprinec.pdf'},
    {nombre: 'Folleto Cepritec', link: '/docs/karizoo/cepritect.pdf'},
    {nombre: 'Folleto Noroclav', link: '/docs/karizoo/noroclav.pdf'},
    {nombre: 'Folleto Rumenil ', link: '/docs/karizoo/rumenil.pdf'},
  ]
  const videos = []
  const contactoInfo = <div>
    <br />
    www.karizoo.com<br />
    karizoo@karizoo.com<br />
    938654148<br />
  </div>
  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/karizoo.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='karizoo@karizoo.com'
      handleClickPoster={() => {
        window.open('/docs/karizoo/rumenil.pdf', '_blank')
      }}
      handleClickRollup={() => {
        window.open('/docs/karizoo/noreprinec.pdf', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.karizoo.com', '_blank')
      }}

    />
  )
}