import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/adial.jpg'
import imgVideo from '../../img/stands/adial-video.jpg'

export default function Adial () {
  
  const catalogos = [
    {nombre: 'Catálogo A-MAX Ultra', link: '/docs/adial/a-max-ultra.pdf'},
    {nombre: 'Catálogo Acid Buf', link: '/docs/adial/acid-buf.pdf'},
    {nombre: 'Catálogo Bg-Max Midds', link: '/docs/adial/bg-max-midds.pdf'},
    {nombre: 'Catálogo BIO-CHLOR', link: '/docs/adial/bio-chlor.pdf'},
    {nombre: 'Catálogo Celmanax', link: '/docs/adial/celmanax.pdf'},
    {nombre: 'Catálogo Kalium+', link: '/docs/adial/kalium-plus.pdf'},
    {nombre: 'Catálogo Silvafeed Bypro', link: '/docs/adial/silvafeed-bypro.pdf'},
    // {nombre: 'Catálogo ', link: '/'},
  ]
  const videos = [
    {nombre: 'Celmanax unlocking the power of the Yeast Cell | Adial | Xornadas Seragro 2021', link: 'https://youtu.be/cKI-F6jpsQE'},
    {nombre: 'Equilibrado Dcad con bioclor | Adial | Xornadas Seragro 2021', link: 'https://youtu.be/MXipeI0CCEg'}
  ]
  const contactoInfo = <div>
    <br/>
    Antón Camarero<br/>
    Veterinario / Delegado Zona Noroeste<br/>
    603378523<br/>
    <br/>
    Alexandre Udina<br/>
    Director técnico<br/>
    649404648<br/>
  </div>

  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/adial.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='adial@adial.es'
      handleClickPoster={() => {
        window.open('https://www.adial.es/', '_blank')
      }}
      handleClickRollup={() => {
        window.open('/docs/adial/kalium-plus.pdf', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.adial.es/', '_blank')
      }}

    />
  )
}