import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/boehringer.jpg'
import imgVideo from '../../img/stands/boehringer-video.jpg'

export default function Boehringer () {

  const catalogos = [
    {nombre: 'Como quitar o tapón de UBROSE correctamente', link: '/docs/boehringer/ubrose.pdf'},
  ]
  const videos = [
    {nombre: 'Entrevista a José Luis Míguez | Boehringer Ingelheim | Xornadas Seragro 2021  ', link: 'https://youtu.be/_Cg0tQOGmb0'}
  ]
  const contactoInfo = <div>
    <br/>
    JESÚS PÉREZ. Delegado de ventas.<br/>
    jesus.perez@boehringer-ingelheim.com.<br/>
    Teléfono: 656813313<br/>
    <br/>
    TERESA CALVO. Delegada de ventas.<br/>
    teresa.calvo@boehringer-ingelheim.com.<br/>
    Teléfono 607566354<br/>
    <br/>
    MANUEL CERVIÑO. Técnico Asesor Veterinario.<br/>
    manuel.cervino@boehringer-ingelheim.com.<br/>
    Teléfono 687931674<br/>
    <br/>
    PILAR FOLCH. Brand Manager.<br/>
    Pilar.folch@boehringer-ingelheim.com.<br/>
    Teléfono 630921095<br/>
  </div>


  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/boehringer.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='pilar.folch@boehringer-ingelheim.com'
      handleClickPoster={() => {
        window.open('/docs/boehringer/rollup.pdf', '_blank')
      }}
      handleClickRollup={() => {
        window.open('/docs/boehringer/rollup.pdf', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.solomamitis.com', '_blank')
      }}

    />
  )
}