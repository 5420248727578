import React from 'react'
import './Silos.css'
import ComunModulo from './ComunModulo'
import imgSilos from '../img/silos.jpg'

export default function Silos ({usuario}) {
  return <ComunModulo
    usuario={usuario}
    zoomId='img-silos-zoom'
    moduloId={3}
    imgModulo={imgSilos}
    titulo='MÓDULO 3 | 19 DE OUTUBRO'
    subtitulo='Economía e nutrición.'
  />
}
