import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/zoetis.jpg'
import imgVideo from '../../img/stands/zoetis-video.jpg'

export default function Zoetis () {

  const catalogos = [
    {nombre: 'Catálogo secado selectivo', link: '/docs/zoetis/secado-selectivo.pdf'},
  ]
  const videos = [
    {'nombre': 'Mamitis e Terapia de Secado Selectivo', link: 'https://www.youtube.com/watch?v=UNnMysjSL44'},
    {'nombre': 'Secado Selectivo', link: 'https://www.youtube.com/watch?v=gu6oNfsTJjY'},
    {'nombre': 'Terapia de Secado Selectivo', link: 'https://www.youtube.com/watch?v=ChfchPqeJy8'},
  ]
  const contactoInfo = <div>
    <br/>
    Julio Díaz<br/>
    Tlf: 677 922 937<br/>
    <br/>
    Nacho Peón<br/>
    Tlf: 677 932 438.<br/>
  </div>
  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/zoetis.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='julio.diaz@zoetis.com'
      handleClickPoster={() => {
        window.open('/docs/zoetis/secado-selectivo.pdf', '_blank')
      }}
      handleClickRollup={() => {
        window.open('https://www.zoetis.es/especies/vacuno/index.aspx', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.zoetis.es/especies/vacuno/index.aspx', '_blank')
      }}

    />
  )
}