import imgBackground from '../img/bg.jpg'
import BtnClose from './BtnClose'

export default function NotaLegal() {

  return <>
    <img id="img-stands-zoom" src={imgBackground} alt='background' className="absolute top-0 left-0 bottom-0 right-0 z-20" />
    <div className="modal-zoom absolute top-8 left-8 bottom-8 right-8 bg-white rounded-lg z-20">
      <BtnClose to='/' />

      <div className='absolute top-10 left-0 bottom-0 right-0 p-8 overflow-y-auto'>
        <h2 className='font-bold text-4xl text-center mt-8 mb-8 text-theme'>Nota Legal:</h2>
        <p align="justify" className='mt-4'>
          De conformidade có disposto no    <strong>Regulamento Xeral (UE) 2016/679 de Protección de Datos</strong>, e
          a
          <strong>
            Lei Orgánica 3/2018 sobre Protección de Datos Persoais e Garantía de
            Dereitos Dixitais
          </strong>
          , informámoslle que os datos proporcionados ao longo da prestación do
          servizo incorporaranse as Actividades de Tratamento titularidade de    <strong>SERAGRO S. COOP. GALEGA</strong>,<strong> </strong>con CIF número F
          15.771.793, e domicilio social en Rúa Castiñeiras, nave 112-A2, 15.895, O
          Milladoiro-Ames (A Coruña). A finalidade do devandito tratamento é a
          xestión das “Xornadas Técnicas do Vacún de Leite – Seragro”.
        </p>
        <p align="justify" className='mt-4'>
          Do mesmo xeito, a empresa informa que os seus datos serán utilizados para
          realizar comunicacións promocionáis dos productos e eventos ofrecidos pola
          organización.
        </p>
        <p align="justify" className='mt-4'>
          Pode exercer os seus dereitos de acceso, rectificación, borrado,
          limitación, oposición e portabilidade en calquera momento por escrito,
          acompañada dunha copia dun documento oficial que identifica dirixido ao
          enderezo arriba indicado ou ao correo electrónico    <u><a href="mailto:seragro@seragro.es">seragro@seragro.es</a></u>. Do mesmo
          xeito, e igualmente, pode revogar o consentimento dado á recepción de
          comunicacións comerciais de conformidade co disposto na Lei 34/2002.
        </p>
        <p align="justify" className='mt-4'>
          Pode consultar información adicional e detallada sobre Protección de Datos
          na nosa <strong>Política de Privacidade</strong> do sitio web    <u><a href="http://www.seragro.es/">www.seragro.es</a></u>
        </p>
        <p align="justify" className='mt-4'>
          Por último, a empresa infórmalle que ao aceptar este documento, concede o
          seu consentimento para o tratamento dos seus datos para o propósito antes
          mencionado.
        </p>

      </div>
    </div>
  </>
}