
import imgAdial from '../img/patrocina/adial.png'
import imgBoehringer from '../img/patrocina/boehringer.png'
import imgCalier from '../img/patrocina/calier.png'
import imgCeva from '../img/patrocina/ceva.png'
import imgCorteva from '../img/patrocina/corteva.png'
import imgDepu from '../img/patrocina/deputacion_lugo.png'
import imgElanco from '../img/patrocina/elanco.png'
import imgFatro from '../img/patrocina/fatro_sergave.png'
import imgHipra from '../img/patrocina/hipra.png'
import imgKarinzoo from '../img/patrocina/karizoo.png'
import imgMSD from '../img/patrocina/MSD.png'
import imgOvejero from '../img/patrocina/ovejero.png'
import imgProgando from '../img/patrocina/progando.png'
import imgSyngenta from '../img/patrocina/syngenta_camponovo.png'
import imgWisium from '../img/patrocina/wisium.png'
import imgXesga from '../img/patrocina/xesga.png'
import imgZoetis from '../img/patrocina/zoetis.png'
import imgGaleno from '../img/patrocina/galeno.png'
import imgBoumatic from '../img/patrocina/boumatic.png'

const patrocinadores = [
  {
    img: imgAdial,
    name: 'imgAdial',
    link: '/stands/adial'
  },
  {
    img: imgBoehringer,
    name: 'imgBoehringer',
    link: '/stands/boehringer'
  },
  {
    img: imgCalier,
    name: 'imgCalier',
    link: '/stands/calier'
  },
  {
    img: imgCeva,
    name: 'imgCeva',
    link: '/stands/ceva'
  },
  {
    img: imgCorteva,
    name: 'imgCorteva',
    link: '/stands/corteva'
  },
  {
    img: imgDepu,
    name: 'imgDepu',
    link: 'http://www.deputacionlugo.gal/'
  },
  {
    img: imgElanco,
    name: 'imgElanco',
    link: '/stands/elanco'
  },
  {
    img: imgFatro,
    name: 'imgFatro',
    link: '/stands/fatro'
  },
  {
    img: imgHipra,
    name: 'imgHipra',
    link: '/stands/hipra'
  },
  {
    img: imgKarinzoo,
    name: 'imgKarinzoo',
    link: '/stands/karinzoo'
  },
  {
    img: imgMSD,
    name: 'imgMSD',
    link: '/stands/msd'
  },
  {
    img: imgOvejero,
    name: 'imgOvejero',
    link: '#'
  },
  {
    img: imgProgando,
    name: 'imgProgando',
    link: '/stands/progando'
  },
  {
    img: imgSyngenta,
    name: 'imgSyngenta',
    link: '/stands/syngenta'
  },
  {
    img: imgWisium,
    name: 'imgWisium',
    link: '/stands/wisium'
  },
  {
    img: imgXesga,
    name: 'imgXesga',
    link: '/stands/xesga'
  },
  {
    img: imgZoetis,
    name: 'imgZoetis',
    link: '/stands/zoetis'
  },
  {
    img: imgGaleno,
    name: 'Galeno',
    link: '/stands/galeno'
  },
  {
    img: imgBoumatic,
    name: 'Boumatic',
    link: '/stands/boumatic'
  },
]

export default patrocinadores