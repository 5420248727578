import React, {useState} from 'react'
import BtnClose from '../BtnClose'
import StandPopup from './StandPopup'
import EmailForm from './EmailForm'

export default function StandBase ({
    img, handleClickPoster, handleClickRollup, handleClickWeb, handleClickOtraWeb,
    catalogos, videos, videoUrl, videoPoster, contactoInfo, emailContacto
  }) {

  const [mostrarCatalogo, setMostrarCatalogo] = useState(false)
  const [mostrarVideos, setMostrarVideos] = useState(false)
  const [mostrarContacto, setMostrarContacto] = useState(false)

  const esStandGuai = (contactoInfo || emailContacto) && (catalogos && catalogos.length) &&
    (videos && videos.length) && handleClickWeb && handleClickOtraWeb
  
  return <div 
    className="modal-zoom absolute top-0 left-0 h-screen right-0 bg-white rounded-lg z-20"
    style={{backgroundColor: '#1C1E20'}}
  >

    <BtnClose to='/stands'/>

    <div className='relative stand-container'>
      <svg
        className='absolute top-0 left-0 right-0 '
        version="1.1" viewBox="0 0 162.56 91.44"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g transform="translate(-46.476 13.97)">
          <image x="46.476" y="-13.97" width="162.56" height="91.44" preserveAspectRatio="none"
                 xlinkHref={`${img}`}/>
          <g fill="#be4b4b" fillOpacity=".51036" strokeWidth=".26458">
            <rect
              x="86.825" y="-1.9897"
              width="43.623" height="50.473"
              className='blink-area-stands'
              onClick={handleClickPoster}
            />
            { (videoUrl && videoPoster) && (
              <foreignObject
                x="134.87" y="9.0925"
                width="37.066" height="20.861"
              >
                <video
                  className='cursor-pointer'
                  poster={videoPoster}
                  width="500" height="400"
                  controls loop
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </foreignObject>
            )}

            <rect
              x="178.58" y="11.643"
              width="28.738" height="58.578"
              className='blink-area-stands'
              onClick={handleClickRollup}
            />
            
            { esStandGuai ? (
              <>
                <rect
                  className='blink-area-stands'
                  id="rect1506"
                  width="22.48148"
                  height="4.3012953"
                  x="63.594898"
                  y="72.622307" 
                  onClick={() => {
                    // catalogo
                    setMostrarCatalogo(true)
                  }}
                />
                <rect
                  className='blink-area-stands'
                  id="rect1508"
                  width="14.320535"
                  height="4.817523"
                  x="96.498924"
                  y="72.558167" 
                  onClick={() => {
                    setMostrarContacto(true)
                  }}
                />
                <rect
                  className='blink-area-stands'
                  id="rect1510"
                  width="14.304445"
                  height="5.5210543"
                  x="118.51781"
                  y="72.367867" 
                  onClick={() => {
                    setMostrarVideos(true)
                  }}
                />
                <rect
                  className='blink-area-stands'
                  id="rect1510-3"
                  width="20.181286"
                  height="5.5210543"
                  x="142.02162"
                  y="72.201805" 
                  onClick={handleClickOtraWeb}
                />
                <rect
                  className='blink-area-stands'
                  id="rect1510-3-3"
                  width="20.181286"
                  height="5.5210543"
                  x="171.8253"
                  y="72.619003" 
                  onClick={handleClickWeb}
                />
              </>
            ) : (
              <>
                <rect
                  x="72.555" y="72.93"
                  width="22.481" height="4.3013"
                  className='blink-area-stands'
                  onClick={() => {
                    // catalogo
                    setMostrarCatalogo(true)
                  }}
                />
                { (videos && videos.length) ? (
                  <>
                    <rect
                      x="105.13" y="72.584"
                      width="14.321" height="4.8175"
                      className='blink-area-stands'
                      onClick={() => {
                        setMostrarContacto(true)
                      }}
                    />
                    <rect
                      x="128.93" y="72.455"
                      width="12.38" height="5.196"
                      className='blink-area-stands'
                      onClick={() => {
                        setMostrarVideos(true)
                      }}
                    />
                  </>
                ) : (
                  <>
                  {handleClickOtraWeb ? (
                      <rect
                        x="105.13" y="72.584"
                        width="14.321" height="4.8175"
                        className='blink-area-stands'
                        onClick={() => {
                          setMostrarContacto(true)
                        }}
                      />
                  ) : (

                    <rect
                      x="117.56" y="72.415"
                      width="14.321" height="4.8175"
                      className='blink-area-stands'
                      onClick={() => {
                        setMostrarContacto(true)
                      }}
                    />
                  )}
                  </>
                )}

                {handleClickOtraWeb ? (

                  <>
                    <rect
                      width="24.844797"
                      height="5.5210543"
                      x="131.72774"
                      y="72.022148"
                      className='blink-area-stands'
                      onClick={handleClickOtraWeb}
                    />
                    <rect
                      width="26.225388"
                      height="5.5210543"
                      x="160.26753"
                      y="72.118271"
                      className='blink-area-stands'
                      onClick={handleClickWeb}
                    />
                  </>
                ) : (
                  <rect
                    x="147.89" y="72.363"
                    width="28.939" height="5.5211"
                    className='blink-area-stands'
                    onClick={handleClickWeb}
                  />
                )}
              </>              
            )}
          </g>
        </g>
      </svg>

      { mostrarCatalogo && <StandPopup titulo='Catálogos' handleClose={() => {setMostrarCatalogo(false)}}>
        <ul className='mt-6'>
          {catalogos.map((catalogo) => (
            <li className='mb-2'>
              <a
                className='text-theme hover:text-blue-600 font-bold'
                href={catalogo.link} rel="noreferrer"
                target='_blank'
              >
                {catalogo.nombre}
              </a>
            </li>
          ))}
        </ul>
      </StandPopup>}

      { mostrarVideos && <StandPopup titulo='Vídeos' handleClose={() => {setMostrarVideos(false)}}>
        <ul className='mt-6'>
          {videos.map(video => (
            <li className='mb-2'>
              <a
                className='text-theme hover:text-blue-600 font-bold'
                href={video.link}
                target='_blank' rel="noreferrer">
                {video.nombre}
              </a>
            </li>
          ))}
        </ul>
      </StandPopup>}

      {mostrarContacto && <StandPopup titulo='Contacto' handleClose={() => {setMostrarContacto(false)}}>
        {emailContacto ? (
          <>{console.info(emailContacto)}
            <EmailForm emailTo={emailContacto} />
            {contactoInfo}
          </>
        ) : (
          contactoInfo
        )}
      </StandPopup>}

    </div>
  </div>
}