
import React, {useState} from 'react'
import BtnClose from '../BtnClose'
import './StandElanco.css'
import stand from '../../img/stands/elanco-lg.jpg'
import imgVideo from '../../img/stands/elanco-video.jpg'

export default function StandElanco() {
  const [contacto, setContacto] = useState(false)

  function handleClick1 () {
    window.open('/docs/elanto-ketorisk-ganaderos.pdf', '_blank')
  }
  // function handleClick2 () {
  //   window.open('https://anchor.fm/podcast-vital90days/episodes/The-Vital-90-Days-Podcast--conversaciones-con-Fernando-Mazeris-sobre-inteligencia-artificial-e14aaej/a-a6471qe', '_blank')
  // }
  function handleClick3 () {
    window.open('https://vetconecta.elanco.com/our-products', '_blank')
  }
  function handleClick4 () {
    window.open('https://vetconecta.elanco.com/our-products', '_blank')
  }
  function handleClick5 () {
    window.open('https://anchor.fm/podcast-vital90days/episodes/The-Vital-90-Days-Podcast--conversaciones-con-Fernando-Mazeris-sobre-inteligencia-artificial-e14aaej/a-a6471qe', '_blank')
  }
  function handleClick6 () {
    setContacto(true)
    // window.open('https://www.elanco.com/en-us', '_blank')
  }
  function handleClick7 () {
    window.open('https://www.elanco.com/en-us', '_blank')
  }
  function handleClick8 () {
    window.open('https://vetconecta.elanco.com/', '_blank')
  }

  return <div
      className="modal-zoom absolute top-0 left-0 h-screen right-0 bg-white rounded-lg z-20 flex"
      style={{backgroundColor: '#1C1E20'}}
    >

    <BtnClose to='/stands'/>

    <div className='relative stand-container'>
      <svg
        className='absolute top-0 left-0 right-0 h-screen'
        version="1.1"
           viewBox="0 0 162.56 91.44" xmlns="http://www.w3.org/2000/svg"
           xmlnsXlink="http://www.w3.org/1999/xlink">
        <g transform="translate(-53.424 -55.842)">
          <image x="53.424" y="55.842" width="162.56" height="91.44" preserveAspectRatio="none"
                 xlinkHref={`${stand}`}/>
          <g fill="#be4b4b" fillOpacity=".51036" strokeWidth=".26458">
            <rect x="93.872" y="67.573" width="36.734" height="51.398" className="blink-area-stands" onClick={handleClick1}/>
            {/*<rect x="141.95" y="78.962" width="36.995" height="21.331" className="blink-area-stands" onClick={handleClick2}/>*/}
            <rect x="185.76" y="82.186" width="28.651" height="57.26" className="blink-area-stands" onClick={handleClick3}/>
            <rect x="70.63" y="142.33" width="23.365" height="5.3896" className="blink-area-stands" onClick={handleClick4}/>
            <rect x="98.367" y="141.95" width="12.41" height="5.4936" className="blink-area-stands" onClick={handleClick5}/>
            <rect x="115.42" y="141.96" width="14.86" height="5.6334" className="blink-area-stands" onClick={handleClick6}/>
            <rect x="134.17" y="142.3" width="27.522" height="5.0404" className="blink-area-stands" onClick={handleClick7}/>
            <rect x="166.12" y="141.85" width="30.119" height="5.4127" className="blink-area-stands" onClick={handleClick8}/>
            <foreignObject
              x="141.95" y="78.962" width="36.995" height="21.331"
            >
              <video
                className='cursor-pointer'
                poster={imgVideo}
                width="500" height="400"
                controls loop
              >
                <source src='/video/elanco.mp4' type="video/mp4" />
              </video>
            </foreignObject>
          </g>
        </g>
      </svg>

      {contacto ? (
        <div className="absolute right-0 top-0 left-0 bottom-0 flex items-center">
          <form className='rounded bg-white mx-auto mx-w-lg p-8'>
            <div className="mb-4 mt-8">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name" type="text" required
                  placeholder="Nome e apelidos"
                />
              </div>

              <div className="mb-4">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email" type="email" required
                  placeholder="nome@dominio.com"
                />
              </div>
              <div className="mb-4">
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Consulta" required
                />
              </div>

              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit">
                  Enviar
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => setContacto(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
        </div>
      ) : (
        <></>
      )}
    </div>


  </div>
}
