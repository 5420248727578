import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/syngenta.jpg'
import imgVideo from '../../img/stands/syngenta-video.jpg'

export default function Syngenta () {

  const catalogos = [
    {nombre: 'Catálogo general millo', link: '/docs/syngenta/general-maiz.pdf'},
    {nombre: 'Catálogo ampligo millo', link: '/docs/syngenta/ampligo-maiz.pdf'},
    {nombre: 'Catálogo callisto plus', link: '/docs/syngenta/callisto-plus.pdf'},
    {nombre: 'Catálogo camix', link: '/docs/syngenta/camix.pdf'},
    {nombre: 'Catálogo casper millo', link: '/docs/syngenta/casper-maiz.pdf'},
    {nombre: 'Catálogo elumis', link: '/docs/syngenta/elumis.pdf'},
    {nombre: 'Catálogo lumax', link: '/docs/syngenta/lumax.pdf'},
    {nombre: 'Catálogo mistral plus', link: '/docs/syngenta/mistral-plus.pdf'},
  ]
  const videos = [
    {nombre: 'Operación Polinizador | Syngenta | Xornadas seragro 2021', link: 'https://youtu.be/TUOb_v_5eCA'},
    {nombre: 'Tratamientos de preemergencia en maíz | Syngenta | Xornadas Seragro 2021', link: 'https://youtu.be/mwyJ7oLimWk '}
  ]
  const contactoInfo = <div>
    <br/>
    CAMPONOVO PROTECCIÓN DE CULTIVOS S.L<br/>
    Rúa Comunidade Valenciana, 13 (Polígono de Sionlla)<br/>
    15707 Santiago de Compostela (A Coruña)<br/>
    Telf: 981535420<br/>
  </div>

  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/syngenta.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='p.cultivos@camponovo.es'
      handleClickPoster={() => {
        window.open('/docs/syngenta/lumax.pdf', '_blank')
      }}
      handleClickRollup={() => {
        window.open('https://www.syngenta.es/', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.syngenta.es/', '_blank')
      }}

    />
  )
}