import React from 'react'
import './Stands.css'
import imgBackground from '../../img/bg.jpg'
import BtnClose from './../BtnClose'
import stands from '../../img/stands.jpg'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router-dom'

const Stands = ({history}) => {

  return <>
    <img
      id="img-stands-zoom"
      src={imgBackground}
      alt='background'
      className="absolute top-0 left-0 h-screen right-0 z-20"
    />
    <div
      className="modal-zoom absolute top-0 left-2 h-screen pb-4 right-2
        bg-white rounded-lg p-0 z-20 "
      style={{backgroundColor: '#9F9D9E'}}
    >

      <BtnClose to='/'/>

      <ReactTooltip html={true} />

      <div className='relative stand-container'>
        <svg
          className='absolute top-0 left-0 right-0 bottom-0'
          version="1.1" viewBox="0 0 508 285.75"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
        <g transform="translate(268.36 46.113)">
          <image
            x="-268.36" y="-46.113" width="508" height="285.75"
            preserveAspectRatio="none" xlinkHref={`${stands}`}
          />
          <path
            d="m110.96 24.262 65.803-7.9123 27.168 48.671-69.191 7.0439z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de Xesga</b>'
            onClick={() => { history.push('/stands/xesga')}}
          />
          <path
            id="elanco" d="m-117.61 122.14 66.935-8.1773 26.589 49.024-69.169 6.8744z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de ELANCO</b>'
            onClick={() => { history.push('/stands/elanco')}}
          />
          <path
            id="corteva" d="m-203.42 133.98 66.935-8.1773 26.589 49.024-69.169 6.8744z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de CORTEVA</b>'
            onClick={() => { history.push('/stands/corteva')}}
          />
          <path
            id="hipra" d="m-32.48 112.52 66.935-8.1773 26.589 49.024-69.169 6.8744z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de HIPRA</b>'
            onClick={() => { history.push('/stands/hipra')}}
          />
          <path
            d="m54.597 100.66 66.935-8.1773 26.589 49.024-69.169 6.8744z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de PROGANDO</b>'
            onClick={() => { history.push('/stands/progando')}}
          />
          <path
            id="zoetis" d="m140.12 90.225 66.935-8.1773 26.589 49.024-69.169 6.8744z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de ZOETIS</b>'
            onClick={() => { history.push('/stands/zoetis')}}
          />
          <path
            id="boumatic" d="m46.959 178.04 66.935-8.1773 26.589 49.024-69.169 6.8744z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de BOUMATIC</b>'
            onClick={() => { history.push('/stands/boumatic')}}
          />
          <path
            id="galeno" d="m-40.263 189.66 66.935-8.1773 26.589 49.024-69.169 6.8744z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de GALENO</b>'
            onClick={() => { history.push('/stands/galeno')}}
          />
          <path
            d="m-232.57 67.619 66.935-8.1773 26.589 49.024-69.169 6.8744z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de CALIER</b>'
            onClick={() => { history.push('/stands/calier')}}
          />
          <path
            d="m-147.62 55.973 66.935-8.1773 26.589 49.024-69.169 6.8744z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de CEVA</b>'
            onClick={() => { history.push('/stands/ceva')}}
          />
          <path
            d="m-62.216 46.377 66.935-8.1773 26.589 49.024-69.169 6.8744z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de FATRO</b>'
            onClick={() => { history.push('/stands/fatro')}}
          />
          <path
            d="m24.781 34.367 66.935-8.1773 26.589 49.024-69.169 6.8744z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de SYNGENTA</b>'
            onClick={() => { history.push('/stands/syngenta')}}
          />
          <path
            id="wisium" d="m83.674-41.133 43.742-5.0577 24.539 0.0178s16.425 30.649 24.784 45.624c0 0-45.52 4.0386-68.601 6.9141z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de WISIUM</b>'
            onClick={() => { history.push('/stands/wisium')}}
          />
          <path
            d="m-1.6201-31.074 65.803-7.9123 27.168 48.671-69.191 7.0439z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de MSD</b>'
            onClick={() => { history.push('/stands/msd')}}
          />
          <path
            id="karizoo" d="m-88.564-18.927 65.803-7.9123 27.168 48.671-69.191 7.0439z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de KARIZOO</b>'
            onClick={() => { history.push('/stands/karizoo')}}
          />
          <path
            d="m-174.03-9.4497 65.803-7.9123 27.168 48.671-69.191 7.0439z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de ADIAL</b>'
            onClick={() => { history.push('/stands/adial')}}
          />
          <path
            id="boehringer" d="m-259.41 2.2334 65.803-7.9123 27.168 48.671-69.191 7.0439z"
            fill="transparent" stroke={null}
            className="blink-area-stands"
            /* filter="url(#svg-blur)"  */
            data-html={true} data-tip='<b>Stand de BOEHRINGER</b>'
            onClick={() => { history.push('/stands/boehringer')}}
          />
        </g>
      </svg>
      </div>

    </div>
  </>
}

export default withRouter(Stands)