import React, {useState} from 'react'

export default function PreguntaForm({charla, handleClose}) {
  const [enviado, setEnviado] = useState(false)
  const [enviando, setEnviando] = useState(false)
  const [formData, setFormData] = useState({
    'nome': '',
    'pregunta': ''
  })

  const emailTo = (process.env.NODE_ENV !== 'production') ? 'tonio.castro@gmail.com' : 'seragro@seragro.es'

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      setEnviando(true)
      const data = new URLSearchParams()
      data.append('nome', formData.nome)
      data.append('consulta', formData.pregunta)
      data.append('to', emailTo)
      data.append('charla', charla.nome)

      const response = await fetch(
        'https://m.xornadasseragro.com/send.php', {
          method: 'POST',
          body: data
        })
      if (response.ok) {
        // setDone(true)
        setEnviado(true)
        setFormData({
          nome: '',
          pregunta: ''
        })
      } else {
        alert('Produciuse un erro enviado o email.')
      }
    } catch (error) {
      console.error(error)
      alert('Produciuse un erro. Ténteo de novo mais tarde.')
    }
    setEnviando(false)
  }

  if (enviado) {
    return <div className='max-w-md mx-auto text-center'>
      <h3 className='text-2xl text-center'>A tua pregunta foi enviada con éxito.</h3>

      <button
        onClick={handleClose}
        className="mt-8 bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline inline-flex items-center"
        type="button"
      >
        Pechar
      </button>
    </div>
  } else {

    return <div className='max-w-md mx-auto'>
      <form onSubmit={handleSubmit}>
        <h3 className='text-2xl text-center'>Fainos chegar a tua pregunta</h3>
        <div className="mb-4 mt-8">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name" type="text" required
            placeholder="o teu nome"
            value={formData.nome}
            onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
          />
        </div>
        <div className="mb-4 mt-8">
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
            placeholder="Pregunta"
            value={formData.pregunta}
            onChange={(e) => setFormData({ ...formData, pregunta: e.target.value })}
          />
        </div>

        <div className="flex items-center ">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline inline-flex items-center"
            type="submit"
          >
            {enviando &&
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
              <path
                className="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>}
            Enviar
          </button>
          <button
            onClick={handleClose}
            className="ml-8 bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline inline-flex items-center"
            type="button"
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  }
}