
import React from 'react'
import './Camas.css'
import ComunModulo from './ComunModulo'
import imgCamas from '../img/camas.jpg'

const Camas = ({usuario}) => {
  return <ComunModulo
    usuario={usuario}
    zoomId='img-camas-zoom'
    moduloId={1}
    imgModulo={imgCamas}
    titulo='MÓDULO 1 | 18 DE SETEMBRO'
    subtitulo='Instalacións e benestar. Influencia na produción.'
  />
}

export default Camas