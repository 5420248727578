import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/wisium.jpg'
import imgVideo from '../../img/stands/wisium-video.jpg'

export default function Wisium () {

  const catalogos = [
    {nombre: 'Catálogo B-SAFE STD', link: '/docs/wisium/B-SAFE-STD.pdf'},
    {nombre: 'Catálogo OILIS-SD', link: '/docs/wisium/OILIS-SD.pdf'},
    {nombre: 'Catálogo POWERJET', link: '/docs/wisium/POWERJET-SD.pdf'},
    {nombre: 'Catálogo PURLITE', link: '/docs/wisium/PURLITE.pdf'},
    {nombre: 'Catálogo T5X-SD', link: '/docs/wisium/T5X-SD.pdf'},
  ]
  const videos = []
  const contactoInfo = <div>
    <br/>
    ADM Portugal, SA<br/>
    Zona Industrial de Murtede<br/>
    3060-372 Murtede – Cantanhede<br/>
    Portugal<br/>
    Tel 00 351 231 209 900<br/>
    Para más informaciones contactar: geral.portugal@wisium.com
  </div>
  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/wisium.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='geral.portugal@wisium.com'
      handleClickPoster={() => {
        window.open('/docs/wisium/Kakemono-Wisium-Espagne.pdf', '_blank')
      }}
      handleClickRollup={() => {
        window.open('https://www.wisium.pt/', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.wisium.pt/', '_blank')
      }}

    />
  )
}