import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/ceva.jpg'
import imgVideo from '../../img/stands/ceva-video.jpg'

export default function Ceva () {

  const catalogos = [
    {nombre: 'Díptico Gando', link: '/docs/ceva/diptico-gando.pdf'},
    {nombre: 'Vademecum 2021', link: '/docs/ceva/vademecum-2021.pdf'},
    {nombre: 'Publirreportaje Vaca Pinta 20', link: 'https://vacapinta.com/media/files/fichero/vp020_ceva.pdf'},
    {nombre: 'Publirreportaje Vaca Pinta 25', link: 'https://vacapinta.com/media/files/fichero/vp025_gando.pdf'},
  ]
  const videos = [
    {nombre: 'Ceva | Gando | Antonio Jiménez', link: 'https://vacapinta.com/es/tv/empresas/gando-la-plataforma-online-para-gestionar-explotac/4316-antonio-jimenez.html'},
    {nombre: 'Ceva | Gando | Guillermo Lorenzo', link: 'https://vacapinta.com/es/tv/empresas/gando-la-plataforma-online-para-gestionar-explotac/4317-guillermo-lorenzo.html'},
    {nombre: 'Ceva | Gando | Luis Fernández', link: 'https://vacapinta.com/es/tv/empresas/gando-un-programa-online-que-'},
  ]
  const contactoInfo = <div>
    <br />
    Ceva Salud Animal S.A<br />
    Avenida Diagonal 609-615. Barcelona. España<br />
    Teléfono: 902 36 72 18<br />
    email: ceva.salud-animal@ceva.com<br />
  </div>


  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/ceva.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='ceva.salud-animal@ceva.com'
      handleClickPoster={() => {
        window.open('/docs/ceva/diptico-gando.pdf', '_blank')
      }}
      handleClickRollup={() => {
        window.open('https://www.ceva.es', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.gando.es', '_blank')
      }}
      handleClickOtraWeb={() => {
        window.open('https://www.ceva.es/', '_blank')
      }}

    />
  )
}