import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Rexistro.css'
import imgBackground from '../img/bg.jpg'
import imgOk from '../img/ok.gif'
import BtnClose from './BtnClose'


const Rexistro = () => {
  const [revista, setRevista] = useState(false)
  const [loading, setLoading] = useState(false)
  const [registrado, setRegistrado] = useState(false)
  const [yaRegistrado, setYaRegistrado] = useState(false)
  const [emailProbado, setEmailProbado] = useState(false)
  const [registroPrimeraVez, setRegistroPrimeraVez] = useState(true)
  const [formData, setFormData] = useState({
    nombre: "",
    profesion: "",
    email: "",
    telefono: "",
    direccion: "",
    cp: "",
    provincia: ""
  })

  const handleClickRevista = () => {
    setRevista(!revista)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    setLoading(false)
  }

  return <>
    <img 
      id="img-rexistro-zoom" 
      src={imgBackground} 
      alt='background' 
      className="absolute top-0 left-0 bottom-0 right-0 z-50"
    />
    <div 
      id="modal-zoom-rexistro" 
      className="modal-zoom absolute top-0 bottom-0 right-0 left-0 
      flex justify-center items-center z-50 flex-col"
      style={{overflow: 'scroll'}}
    >
      <div className="w-full max-w-lg min-h-0 my-16  mx-2"> {/* min-h-0 para que funcione o scroll! */}
        <div
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 relative"
        >
          {registrado ? (
            <div className=''>
              <h2 className='text-2xl font-bold text-center mb-4'>
                Inscrición Correcta!
              </h2>
              { registroPrimeraVez &&
                <h3 className='text-xl text-center'>
                  Acabamos de enviarche unha ligazón de inicio de sesión o teu email.
                  Se non e así, por favor, revisa a tua carpeta de SPAM.
                </h3>
              }
              <img src={imgOk} alt='OK' className='mx-auto mt-4' />
              <div className="text-center mt-8">
                <Link
                  to='/'
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded
                      focus:outline-none focus:shadow-outline inline-flex items-center"
                >
                  Continuar
                </Link>
              </div>
            </div>
          ): (
            <form
              onSubmit={handleSubmit}
            >
              <BtnClose />

              { !emailProbado ? (
                <>
                  <h2 className='text-2xl pb-8'>Introduce o teu email para acceder.</h2>
                  <div className="mb-8">
                    <label className="hidden block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="email" type="email" required
                      value={formData.email}
                      onChange={(e) => setFormData({...formData, email: e.target.value})}
                      placeholder="nome@dominio.com"
                    />
                  </div>

                  <div className="mb-8">
                    <label className="block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="politica">
                      <input type='checkbox' id='politica' className='mr-2' required/>
                      Acepto a condicións recollidas na
                      <Link to='/nota-legal' className='text-theme'> nota legal</Link>.
                    </label>
                  </div>


                  <div className="flex items-center justify-between">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded
                    focus:outline-none focus:shadow-outline inline-flex items-center"
                      type="submit"
                    >
                      { loading &&
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24"
                      >
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                        <path
                          className="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>}
                      Acceder
                    </button>
                    {/*
                  <a className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                    Forgot Password?
                  </a>
                  */}
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-4 mt-8">
                    <label className="hidden block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="name">
                      Nome e apelidos
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="name" type="text" required
                      value={formData.nombre}
                      onChange={(e) => setFormData({...formData, nombre: e.target.value})}
                      placeholder="Nome e apelidos"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="hidden block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="profesion">
                      Profesión
                    </label>
                    <div className="inline-block relative w-full">
                      <select
                        value={formData.profesion}
                        onChange={(e) => setFormData({...formData, profesion: e.target.value})}
                        required
                        id='profesion'
                        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                        <option value='' disabled selected>Seleccione Profesión...</option>
                        <option>Gandeiro</option>
                        <option>Veterinario</option>
                        <option>Técnico</option>
                        <option>Estudante</option>
                        <option>Outro</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="mb-4">
                    <label className="hidden block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="email" type="email" required
                      value={formData.email}
                      onChange={(e) => setFormData({...formData, email: e.target.value})}
                      placeholder="nome@dominio.com"
                    />
                    {yaRegistrado && <div className='text-red-500'>
                      Este email xa está rexistrado. Revise o seu correo electrónico e acceda a través da
                      ligazón do mesmo.
                    </div>}
                    {emailProbado && <div className='text-red-500'>
                      Este email non está rexistrado. Debes completar os datos para acceder.
                    </div>}
                  </div>

                  <div className="mb-8">
                    <label className="hidden block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="telefono">
                      Teléfono
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="telefono" type="number" required
                      value={formData.telefono}
                      onChange={(e) => setFormData({...formData, telefono: e.target.value})}
                      placeholder="teléfono"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="revista">
                      <input
                        id='revista'
                        type='checkbox'
                        className='mr-2'
                        checked={revista}
                        onChange={handleClickRevista}
                      />
                      Quero recibir unha copia impresa da revista das xornadas no meu domicilio <sup>(1) (2)</sup>.
                    </label>
                  </div>

                  {revista && <>
                    <div className="mb-8">
                      <label className="hidden block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="direccion">
                        Dirección
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="direccion" type="text" required={revista}
                        value={formData.direccion}
                        onChange={(e) => setFormData({...formData, direccion: e.target.value})}
                        placeholder="Dirección, lugar, rua..."
                      />
                    </div>
                    <div className="mb-8">
                      <label className="hidden block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="cp">
                        Código postal
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="cp" type="number" required={revista}
                        value={formData.cp}
                        onChange={(e) => setFormData({...formData, cp: e.target.value})}
                        placeholder="Código Postal"
                      />
                    </div>
                    <div className="mb-8">
                      <label className="hidden block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="provincia">
                        Provincia
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="provincia" type="text" required={revista}
                        value={formData.provincia}
                        onChange={(e) => setFormData({...formData, provincia: e.target.value})}
                        placeholder="Provincia"
                      />
                    </div>
                  </>}



                  <div className="mb-8">
                    <label className="block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="politica">
                      <input type='checkbox' id='politica' className='mr-2' required/>
                      Acepto a condicións recollidas na
                      <Link to='/nota-legal' className='text-theme'> nota legal</Link>.
                    </label>
                  </div>


                  <div className="flex items-center justify-between">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded
                    focus:outline-none focus:shadow-outline inline-flex items-center"
                      type="submit"
                    >
                      { loading &&
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24"
                      >
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                        <path
                          className="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>}
                      Rexistrarse {revista ? 'e obter a revista' : ''}
                    </button>
                    {/*
                  <a className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                    Forgot Password?
                  </a>
                  */}
                  </div>

                  <div>
                    <small className='block text-gray-600 mt-4'>
                      (1) Unicamente se enviarán revistas ó territorio nacional.
                      Ós demais destinos enviaráse unha copia dixital por email.
                    </small>
                    <small className='block text-gray-600'>
                      (2) Unicamente se enviarán as primeiras 1.000 solicitudes.
                    </small>
                  </div>
                </>
              )}

            </form>
          )}
        </div>
        <p className="text-center text-gray-200 text-xs">
          &copy;2021 Seragro S.L.
        </p>
      </div>
    </div>
  </>
  
}

export default Rexistro