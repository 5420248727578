
import React from 'react'
import StandBase from './StandBase'
import imgVideo from '../../img/stands/xesga-video.jpg'
import img from '../../img/stands/xesga.jpg'

export default function StandXesga() {
  const catalogos = [
    {nombre: 'Catálogo de productos Xesga', link: '/docs/xesga/xesga-productos.pdf'},
    {nombre: 'Optisil - Silos de herba e plásticos.', link: '/docs/xesga/xesga-optisil-silos-herba-e-plasticos.pdf'},
    {nombre: 'Optisil - Maiz.', link: '/docs/xesga/xesga-optisil-maiz.pdf'},
    {nombre: '«SheDip»', link: '/docs/xesga/xesga-triptico-shedip.pdf'},
    // {nombre: 'Optisil - Publireportaxe.', link: '/docs/xesga/xesga-publireportaxe-optisil.pdf'},
    // {nombre: 'Catálogo Kalium+', link: '/docs/adial/kalium-plus.pdf'},
    // {nombre: 'Catálogo Silvafeed Bypro', link: '/docs/adial/silvafeed-bypro.pdf'},
  ]
  const videos = [
    {nombre: 'Publirreportaxe Optisil Vaca Pinta 23', link: 'https://vacapinta.com/media/files/fichero/vp023_publirreportaxe_optisil_castelan.pdf'},
    {nombre: 'Recomendaciones para conseguir un buen ensilado', link: 'https://vacapinta.com/es/tv/empresas/experiencias-con-optisil-kofasil-el-conservante-qu/4455-recomendaciones-para-conseguir-un-buen-ensilado.html'},
    {nombre: 'Agrociocende SC', link: 'https://vacapinta.com/es/tv/empresas/experiencias-con-optisil-kofasil-el-conservante-qu/4429-agrociocende-sc.html'},
  ]
  const contactoInfo = <div>
    <br/>
    <address className='mt-6'>
      Rúa Castiñeiras, nave 112-A2 Pol. Ind. Milladoiro - Ames (A Coruña) |
      Tel.: 981 94 17 94 | xesga@xesga.net
    </address>
    <ul className='mt-4'>
      <li>Delegación Lalín: 986 79 23 73</li>
      <li>Delegación Muimenta: 982 52 81 14</li>
      <li>Delegación Santa Comba: 981 88 09 72</li>
      <li>Delegación Curtis: 981 78 94 93</li>
      <li>Delegación Carballo: 981 70 14 44</li>
    </ul>
  </div>

  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/xesga.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='xesga@xesga.net'
      handleClickPoster={() => {
        window.open('/docs/xesga/xesga-optisil-maiz.pdf', '_blank')
      }}
      handleClickRollup={() => {
        window.open('https://www.xesga.net', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.xesga.net', '_blank')
      }}

    />
  )
}