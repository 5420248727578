import React, { useEffect, useState, useRef } from 'react'
// import Moment from 'react-moment';
import { useParams, useLocation } from "react-router-dom"
import {
  FacebookShareButton, FacebookIcon,
  TwitterShareButton, TwitterIcon,
  WhatsappShareButton, WhatsappIcon,
  TelegramShareButton ,TelegramIcon
} from 'react-share'
import 'moment/locale/gl';
import moment from 'moment'
import imgBackground from '../img/bg.jpg'
import BtnClose from './BtnClose'
import PreguntaForm from './PreguntaForm'
import {FIREBASE_MODULES_DATA} from "../data";

moment().locale('gl')
// Moment.globalTimezone = 'Europe/Madrid'
// Moment.globalLocale = 'gl';

function Video ({title='Vídeo en directo', charla}) {
  const [usuario, setUsuario] = useState(false)
  useEffect(() => {
    setUsuario(window.localStorage.getItem('emailForSignIn'))
  }, [usuario])

  // console.info(charla)
  if (charla && charla.video) {
    let url = charla.video
    if (url.startsWith('https://youtu.be/')) {
      url = url.replace('https://youtu.be/', 'https://www.youtube.com/embed/')
    }
    if (url.includes('?t=')) {
      url = url.replace('?t=', '?start=')
    }
    // console.info(url)
    // if (usuario) {
    if (true) { // Facer os vídeos sempre públicos
      return <>
        <div className="aspect aspect-w-16 aspect-h-9 mtt0">
          <iframe
            width="560" height="315"
            src={url}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <PreguntasCharla charla={charla}/>
      </>
    } else {
      return <div className='bg-red-500 text-white text-center font-bold py-12 px-4'>
        <h4 className='text-2xl md:text-4xl'>Debes estar rexistrado para acceder ás charlas.</h4>
        <a
          href='/rexistro'
          className="mt-12 text-2xl bg-gray-900 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded
                      focus:outline-none focus:shadow-outline inline-flex items-center"
        >
          Rexístrate aquí!
        </a>
      </div>
    }
  } else {
    return null
  }
}

const CompartirCharla = ({charla, className}) => {
  const routerLocation = useLocation()
  let location = window.location

  if (routerLocation && charla && charla.id) {
    if (routerLocation.pathname.split('/').length === 2) { // url de un módulo sin especificar Id de charla
      location = window.location.origin + routerLocation.pathname + '/' + charla.id
    }
  }

  let quote = 'XVIII Xornadas Técnicas de Vacún de Leite de Seragro'
  if (charla && charla.nome) {
    quote += ': ' + charla.nome
  }
  return <div className={`text-center flex justify-center mb-8 ${className}`}>
    <FacebookShareButton quote={quote} url={location} className='mx-4' >
      <FacebookIcon size={48} round={true} />
    </FacebookShareButton>
    <TwitterShareButton quote={quote} url={location} className='mx-4' >
      <TwitterIcon size={48} round={true} />
    </TwitterShareButton>
    <TelegramShareButton quote={quote} url={location}>
      <TelegramIcon size={48} round={true}  className='mx-4' />
    </TelegramShareButton>
    <WhatsappShareButton quote={quote} url={location}>
      <WhatsappIcon size={48} round={true}  className='mx-4' />
    </WhatsappShareButton>
  </div>
}

const PreguntasCharla = ({charla}) => {
  const [formulario, setFormulario] = useState(false);

  const handleClickEmail = () => {
    setFormulario(true)
  }

  const handleCloseFormulario = () => {
    setFormulario(false)
  }

  // console.info(charla)
  if (charla && charla.visible) {
    return <div
      className='p-6 bg-gray-800 text-white'
    >
      {formulario ? (
        <PreguntaForm charla={charla} handleClose={handleCloseFormulario}/>
      ) : (
        <div className="flex items-center justify-around">
          <div className='text-xl'>
            Tes algunha pregunta?
          </div>
          <button
            onClick={handleClickEmail}
            className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded
                focus:outline-none focus:shadow-outline inline-flex items-center"
          >
            <span className='hidden '>Faina por </span> &nbsp;Email
          </button>
          <a
            target='_blank' rel="noreferrer"
            href='https://api.whatsapp.com/send?phone=34981941784'
            className="ml-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded
                focus:outline-none focus:shadow-outline inline-flex items-center"
          >
            WhatsApp
          </a>
        </div>
      )}

    </div>
  }
  return null
}

const ComunModulo = ({match, zoomId, moduloId, imgModulo, titulo, subtitulo, usuario}) => {
  const scrolled = useRef(null)
  const [charlas, setCharlas] = useState([]);
  const [charlaVisible, setCharlaVisible] = useState(null)
  const [charlaSeleccionada, setCharlaSeleccionada] = useState(null)
  const { id } = useParams()

  useEffect(() => {
    let data = FIREBASE_MODULES_DATA[moduloId]
    data = data.sort((a, b) => a.orden - b.orden)
    data = data.filter(a => !a.oculto)
    setCharlas(data)

    let charlaById = null
    if (id) {
      charlaById = data.find(a => a.id === id)
    }

    if (charlaById) {
      setCharlaVisible(charlaById)
    } else {
      setCharlaVisible(data.find(a => a.visible))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickCharla = (charla) => {
    setCharlaSeleccionada(charla)
    if (scrolled && scrolled.current) {
      setTimeout(() => scrolled.current.scroll({top: 0, left: 0, behavior: 'smooth'}), 50)
    }
  }

  const ahoraMs = (new Date()).getTime() / 1000
  const siguientes = charlas.filter((c) => {
    // console.info([c.inicio.seconds, ahoraMs])
    return c.inicio.seconds >= ahoraMs
  })
  let siguiente = null
  if (siguientes && siguientes.length) {
    siguiente = siguientes[0]
  }

  // console.info(charlas)
  // console.info(charlas.find(a => a.visible))
  return <>
    <img id={zoomId} src={imgBackground} alt='background' className="absolute top-0 left-0 bottom-0 right-0 z-20" />
    <div
      style={{backgroundImage: `url(${imgModulo})`}}
      className="modal-zoom absolute
        h-screen
        top-1 left-1 right-1
         md:left-8 md:top-8 md:right-8
        bg-white md:rounded-lg z-20"
    >
      <BtnClose />
      <div
        className='absolute top-0 md:top-8 right-0 bottom-0 left-0
          px-0 md:px-8 scroll'
          style={{overflowY: 'scroll'}}
          ref={scrolled}
        >
        <div className='pt-0 md:pt-4'>
          <div className='bg-gray-100 md:rounded mb-4'>
            <div className='p-4 flex flex-row'>
              <div className='flex-grow'>
                <h2 className='text-lg md:text-4xl font-bold text-theme'>
                  {titulo}
                </h2>
                <div className=' md:text-2xl font-bold text-theme'>
                  {subtitulo}
                </div>
              </div>
              <div className='hidden md:block'>
                <CompartirCharla className='' />
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col md:flex-row px-8 md:px-0'>
          <div className='w-full md:w-3/5 mb-4'>
            {charlaSeleccionada ? (
              <div className='bg-gray-100 md:mr-4 rounded overflow-hidden mb-4'>
                <Video charla={charlaSeleccionada} />
                <div className='px-4 pt-0 pb-8 text-center'>
                  <div className='pt-4 text-theme text-2xl md:text-4xl font-bold'>
                    &laquo;{charlaSeleccionada.nome}&raquo;
                  </div>
                  <h2 className='text-2xl text-bold'>
                    {charlaSeleccionada.autor}
                  </h2>
                  <div>
                    {charlaSeleccionada.subtitulo}
                  </div>
                  {!charlaSeleccionada.video && <div>
                    Comeza o {moment.unix(charlaSeleccionada.inicio.seconds).format('LLL')}
                  </div>}
                </div>
                {charlaSeleccionada.artigo && <div className='text-center mb-10'>
                  <a
                    className='bg-transparent hover:bg-logo text-logo font-semibold hover:text-white py-2 px-4 border border-logo hover:border-transparent rounded'
                    href={charlaSeleccionada.artigo} target='_blank' rel="noreferrer"
                  >
                    Descargar Artigo (PDF)
                  </a>
                </div>}
                <CompartirCharla charla={charlaSeleccionada} />
              </div>
            ) : (
              <>
              {charlaVisible ? (
                <div className='bg-gray-100 md:mr-4 rounded overflow-hidden mb-4'>
                  {/*console.info(charlaVisible)*/}
                  <Video charla={charlaVisible}/>
                  <div className='px-4 pt-0 pb-8 text-center'>
                    <div className='pt-4 text-theme text-2xl md:text-4xl font-bold'>
                      &laquo;{charlaVisible.nome}&raquo;
                    </div>
                    <h2 className='text-2xl text-bold'>
                      {charlaVisible.autor}
                    </h2>
                    <div>
                      {charlaVisible.subtitulo}
                    </div>
                  </div>
                  <CompartirCharla charla={charlaVisible} />
                </div>
              ) : (
                <div className='bg-gray-100 md:mr-4 rounded mb-4'>
                  <div className='px-4 py-8 text-center'>

                    {siguiente ?
                      <>
                        <h2 className='text-2xl text-bold'>
                          Próxima presentación:
                        </h2>
                        <div className='pt-4 text-theme text-2xl md:text-4xl font-bold'>
                          &laquo;{siguiente.nome}&raquo;
                        </div>
                        <div>
                          Comeza o {moment.unix(siguiente.inicio.seconds).format('LLL')}
                        </div>
                        <br /><br />
                        <CompartirCharla charla={siguiente}/>
                      </>
                      : <></>
                    }
                  </div>
                </div>
              )}
            </>
            )}
          </div>
          <div className='bg-gray-100 rounded p-4 md:w-2/5 mb-8'>
            {charlas.map(charla => {
              return <div
                key={charla.id}
                className={`flex mb-4 cursor-pointer ${charlaSeleccionada && charla.id === charlaSeleccionada.id ? 'bg-blue-100 ': ''}`}
                onClick={() => handleClickCharla(charla)}
              >
                <div className='pr-4 flex-grow flex-shrink' style={{flexGrow: 0, flexShrink: 0, flexBasis: '6rem'}}>
                  { charla.avatar ? (
                    <img
                      alt={`[${ charla.autor }]`}
                      src={`/img/autores/${charla.avatar}`}
                      className=''
                    />
                  ) : (
                    <img
                      alt='seragro'
                      src='/logo192.png'
                      className=''
                    />
                  )}

                  {charla.patrocina &&
                    <img
                      alt={`[${charla.patrocina}]`}
                      src={`/img/patrocinadores/${charla.patrocina_logo}`}
                      className=''
                    />
                  }
                </div>
                <div className='text-left '>
                  <div className='font-bold text-black text-lg'>
                    {charla.nome}
                  </div>
                  <div className='text-tema font-bold'>{charla.autor}</div>
                  <div className='text-sm'>{charla.subtitulo}</div>
                  <div className='text-tema text-sm font-normal whitespace-nowrap'>
                    {moment.unix(charla.inicio.seconds).format('DD/MM/YYYY [ás] HH:mm')}
                  </div>
                  <div>
                    {charla.visible && <div className='inline-flex items-center mt-2 font-bold text-green-600 text-sm  animate-pulse'>
                      <svg
                        className='-ml-1 mr-3 h-6 w-6'
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                        fill="currentColor"
                      >
                        <path d="M150.94 192h33.73c11.01 0 18.61-10.83 14.86-21.18-4.93-13.58-7.55-27.98-7.55-42.82s2.62-29.24 7.55-42.82C203.29 74.83 195.68 64 184.67 64h-33.73c-7.01 0-13.46 4.49-15.41 11.23C130.64 92.21 128 109.88 128 128c0 18.12 2.64 35.79 7.54 52.76 1.94 6.74 8.39 11.24 15.4 11.24zM89.92 23.34C95.56 12.72 87.97 0 75.96 0H40.63c-6.27 0-12.14 3.59-14.74 9.31C9.4 45.54 0 85.65 0 128c0 24.75 3.12 68.33 26.69 118.86 2.62 5.63 8.42 9.14 14.61 9.14h34.84c12.02 0 19.61-12.74 13.95-23.37-49.78-93.32-16.71-178.15-.17-209.29zM614.06 9.29C611.46 3.58 605.6 0 599.33 0h-35.42c-11.98 0-19.66 12.66-14.02 23.25 18.27 34.29 48.42 119.42.28 209.23-5.72 10.68 1.8 23.52 13.91 23.52h35.23c6.27 0 12.13-3.58 14.73-9.29C630.57 210.48 640 170.36 640 128s-9.42-82.48-25.94-118.71zM489.06 64h-33.73c-11.01 0-18.61 10.83-14.86 21.18 4.93 13.58 7.55 27.98 7.55 42.82s-2.62 29.24-7.55 42.82c-3.76 10.35 3.85 21.18 14.86 21.18h33.73c7.02 0 13.46-4.49 15.41-11.24 4.9-16.97 7.53-34.64 7.53-52.76 0-18.12-2.64-35.79-7.54-52.76-1.94-6.75-8.39-11.24-15.4-11.24zm-116.3 100.12c7.05-10.29 11.2-22.71 11.2-36.12 0-35.35-28.63-64-63.96-64-35.32 0-63.96 28.65-63.96 64 0 13.41 4.15 25.83 11.2 36.12l-130.5 313.41c-3.4 8.15.46 17.52 8.61 20.92l29.51 12.31c8.15 3.4 17.52-.46 20.91-8.61L244.96 384h150.07l49.2 118.15c3.4 8.16 12.76 12.01 20.91 8.61l29.51-12.31c8.15-3.4 12-12.77 8.61-20.92l-130.5-313.41zM271.62 320L320 203.81 368.38 320h-96.76z"/>
                      </svg>
                      Charla en emisión
                    </div>}
                    {charla.finalizado && <div className='inline-flex items-center mt-2 text-gray-500 text-sm '>
                      <svg
                        className='-ml-1 mr-3 h-6 w-6'
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        fill="currentColor"
                      >
                        <path d="M488 64h-8v20c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12V64H96v20c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12V64h-8C10.7 64 0 74.7 0 88v336c0 13.3 10.7 24 24 24h8v-20c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v20h320v-20c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v20h8c13.3 0 24-10.7 24-24V88c0-13.3-10.7-24-24-24zM96 372c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm272 208c0 6.6-5.4 12-12 12H156c-6.6 0-12-5.4-12-12v-96c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v96zm0-168c0 6.6-5.4 12-12 12H156c-6.6 0-12-5.4-12-12v-96c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v96zm112 152c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40z"/>
                      </svg>
                      Emisión finalizada. Preme para volver a velo.
                    </div>}
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>

        <CompartirCharla className='md:hidden' />

      </div>
    </div>
  </>
}

export default ComunModulo
