import React from 'react'
import './Sala.css'
import imgSala from '../img/sala.jpg'
import ComunModulo from './ComunModulo'

export default function Sala ({usuario}) {
  return <ComunModulo
    usuario={usuario}
    zoomId='img-sala-zoom'
    moduloId={2}
    imgModulo={imgSala}
    titulo='MÓDULO 2 | 18 DE OUTUBRO'
    subtitulo='Uso responsable de antibióticos. Unha necesidade e un beneficio.'
  />
}