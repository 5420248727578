import React from 'react'
import StandBase from './StandBase'
import img from '../../img/stands/hipra.jpg'
import imgVideo from '../../img/stands/hipra-video.jpg'

export default function Hipra () {

  const catalogos = [
    {nombre: 'Catálogo UBAC', link: '/docs/hipra/ubac.pdf'},
    {nombre: 'Catálogo STARTVAC', link: '/docs/hipra/startvac.pdf'},
  ]
  const videos = [
    {nombre: 'Los beneficios de Startvac | Hipra | Xornadas Seragro 2021', link: 'https://www.youtube.com/watch?v=xTg8h-kxaho&list=PLc7bq6dFEJ5H8o6S4-6hMX-JsjW4gSVW6&index=19&ab_channel=VacaTV-Transmedia'},
    {nombre: 'Las ventajas de Ubac | Hipra | Xornadas Seragro 2021', link: 'https://www.youtube.com/watch?v=B9S6J4i1VAE&list=PLc7bq6dFEJ5H8o6S4-6hMX-JsjW4gSVW6&index=19&ab_channel=VacaTV-Transmedia'},
  ]
  const contactoInfo = <div>
    <br/>
    Antonio Souto<br/>
    Servicio técnico mamitis rumiantes HIPRA<br/>
    626 290 803<br/>
    antonio.souto@hipra.com<br/>
    <br/>
    Juan J. Sande<br/>
    Representante de ventas<br/>
    619 265 527<br/>
    xan.sande@hipra.com<br/>
    <br/>
    Iván Mato Iglesias<br/>
    Servicio técnico rumiantes HIPRA<br/>
    606 869 586<br/>
    iIvan.mato@hipra.com<br/>
  </div>
  return (
    <StandBase
      videoPoster={imgVideo}
      videoUrl={'/video/hipra.mp4'}
      img={img}
      catalogos={catalogos}
      videos={videos}
      contactoInfo={contactoInfo}
      emailContacto='antonio.souto@hipra.com'
      handleClickPoster={() => {
        window.open('/docs/hipra/ubac.pdf', '_blank')
      }}
      handleClickRollup={() => {
        window.open('https://www.hipra.com', '_blank')
      }}
      handleClickWeb={() => {
        window.open('https://www.hipra.com', '_blank')
      }}

    />
  )
}