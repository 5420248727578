import imgRural from '../img/colabora/caixa_rural.png'
import cargill from '../img/colabora/cargill.png'
import cenavisa from '../img/colabora/cenavisa.png'
import concello from '../img/colabora/concello_lugo.png'
import DFV from '../img/colabora/DFV.png'
import distribuciones_cutrin from '../img/colabora/distribuciones_cutrin.png'
import diversey from '../img/colabora/diversey.png'
import ecuphar from '../img/colabora/ecuphar.png'
import grupo_ogalco from '../img/colabora/grupo_ogalco.png'
import grupo_sanjurjo from '../img/colabora/grupo_sanjurjo.png'
import hayedo from '../img/colabora/hayedo.png'
import huvepharma from '../img/colabora/huvepharma.png'
import lidea from '../img/colabora/lidea.png'
import vetia from '../img/colabora/vetia.png'
import vetoquinol from '../img/colabora/vetoquinol.png'

const colaboradores = [
  {
    img: imgRural,
    name: 'imgRural',
    link: 'https://www.caixaruralgalega.gal/'
  },
  {
    img: cargill,
    name: 'cargill',
    link: 'https://www.cargill.es/es/inicio'
  },
  {
    img: cenavisa,
    name: 'cenavisa',
    link: 'https://cenavisa.com/es'
  },
  {
    img: concello,
    name: 'concello',
    link: 'http://concellodelugo.gal'
  },
  {
    img: DFV,
    name: 'DFV',
    link: 'http://www.divasa-farmavic.com/'
  },
  {
    img: distribuciones_cutrin,
    name: 'distribuciones_cutrin',
    link: 'http://distribucionescutrin.es/'
  },
  {
    img: diversey,
    name: 'diversey',
    link: 'http://diverseysolutions.com/es'
  },
  {
    img: ecuphar,
    name: 'ecuphar',
    link: 'https://www.ecuphar.es/'
  },
  {
    img: grupo_ogalco,
    name: 'grupo_ogalco',
    link: 'http://www.ogalco.com/'
  },
  {
    img: grupo_sanjurjo,
    name: 'grupo_sanjurjo',
    link: 'http://www.almacenessanjurjo.com/'
  },
  {
    img: hayedo,
    name: 'hayedo',
    link: '#'
  },
  {
    img: huvepharma,
    name: 'huvepharma',
    link: 'https://www.huvepharma.com/'
  },
  {
    img: lidea,
    name: 'lidea',
    link: 'https://lidea-seeds.es/'
  },
  {
    img: vetia,
    name: 'vetia',
    link: 'https://vetia.es/'
  },
  {
    img: vetoquinol,
    name: 'vetoquinol',
    link: 'https://www.vetoquinol.es/'
  },
]

export default colaboradores