
import React from 'react'
import './Brinde.css'
import imgBrinde from '../img/brinde.jpg'
import ComunModulo from './ComunModulo'

const Brinde = ({usuario}) => {
  return <ComunModulo
    usuario={usuario}
    zoomId='img-camas-zoom'
    moduloId={6}
    imgModulo={imgBrinde}
    titulo='Brinde do Leite'
    subtitulo=''
  />
}

export default Brinde